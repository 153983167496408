/**
 * A station specification
 */
export interface StationSpec {
    readonly [key: string]: string | number;

    /** The runtime environment: dev | uat | prod */
    readonly env: string;

    /** The station unique identifier */
    readonly stationID: number;
}

/** Returns an empty {@link StationSpec} instance */
export function emptyStationSpec(): StationSpec {
    return {
        env: "",
        stationID: 0,
    };
}

/**
 * Details of a bike station
 */
export interface StationSummary {
    /** The runtime environment: dev | uat | prod */
    env: string;

    /** The city unique identifier */
    cityID: number;

    /** The city code */
    cityCode: string;

    /** The city name */
    cityName: string;

    /** The station unique identifier */
    stationID: number;

    /** The station code */
    stationCode: string;

    /** The station name */
    stationName: string;
}

/** Returns an empty {@link StationSummary} instance */
export function emptyStationSummary(): StationSummary {
    return {
        env: "",
        cityID: 0,
        cityCode: "",
        cityName: "",
        stationID: 0,
        stationCode: "",
        stationName: "",
    };
}

/**
 * State of a bike station
 */
export interface StationState {
    screenType: string;

    stationState: string;

    connectivityState: string;

    isAuthenticated: boolean;

    isReleaseInProgress: boolean;

    isProcessingAnyRequest: boolean;

    isNetworkDisabled: boolean;

    lastKeepAliveResponseDate: string;

    rebootRequestDate: string;
}

/** Returns an empty {@link StationState} instance */
export function emptyStationState(): StationState {
    return {
        screenType: "",
        stationState: "",
        connectivityState: "",
        isAuthenticated: false,
        isReleaseInProgress: false,
        isProcessingAnyRequest: false,
        isNetworkDisabled: false,
        lastKeepAliveResponseDate: "",
        rebootRequestDate: "",
    };
}

/**
 * Result of a station reboot request
 */
export interface StationRebootPayload {
    acknowledged: boolean;
}
