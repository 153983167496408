import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import React from "react";
import { useRecoilValue } from "recoil";
import { UserProfile } from "../../../models";
import { connectedMembersQuery } from "../../../state";
import { makeMemberAvatars, MemberAvatarClassDefs } from "./members";

function MemberAvatarGroup({ user, classes }: MemberToolbarProps) {
    const members = useRecoilValue(connectedMembersQuery);

    const avatars = makeMemberAvatars(user, members, classes);

    return <AvatarGroup max={4} children={avatars}></AvatarGroup>;
}

export interface MemberToolbarClassDefs extends MemberAvatarClassDefs {
    rightPanel: string;
}

export interface MemberToolbarProps {
    user: UserProfile;
    classes: MemberToolbarClassDefs;
}

export function MemberToolbar({ user, classes }: MemberToolbarProps) {
    return (
        <Box className={classes.rightPanel}>
            <React.Suspense fallback={<CircularProgress size="20px" />}>
                <MemberAvatarGroup user={user} classes={classes} />
            </React.Suspense>
        </Box>
    );
}
