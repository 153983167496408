import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Slide from "@material-ui/core/Slide";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { TransitionProps } from "@material-ui/core/transitions";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { isNonEmptyString, show } from "../../../common";
import { parseLogFile } from "../../../common/stationLogFile";
import { useAmbient } from "../../../context";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(function (theme: Theme) {
    return createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(4),
        },
        content: {
            display: "flex",
        },
        notification: {
            marginTop: "24px",
        },
        text: {
            flex: "auto",
        },
    });
});

export interface LogFormProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export const LogForm: React.FC<LogFormProps> = ({
    isOpen,
    setIsOpen,
}: LogFormProps) => {
    const classes = useStyles();

    const { station, stationSpec } = useAmbient();

    const [isLoading, setIsLoading] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");

    const [text, setText] = useState("");

    const [isParsed, setIsParsed] = useState(false);

    useEffect(
        function () {
            setIsLoading(true);

            station
                .getLogFileContent(stationSpec, "StationLog.log")
                .then(function (text) {
                    setIsLoading(false);
                    setText(text);
                })
                .catch(function (err) {
                    setIsLoading(false);
                    setErrorMessage(show(err));
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stationSpec]
    );

    function handleClose() {
        setText("");
        setErrorMessage("");
        setIsOpen(false);
    }

    function handleParse() {
        parseLogFile(text)
            .then(function (lines) {
                setIsParsed(true);
                setText("[\n".concat(lines.join(",\n"), "\n]"));
            })
            .catch(function (err) {
                setErrorMessage(show(err));
            });
    }

    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar variant="dense">
                    <IconButton
                        edge="start"
                        color="inherit"
                        title="Close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="body1" className={classes.title}>
                        Station Log File
                    </Typography>
                    {!isParsed && !isLoading && (
                        <Button
                            color="inherit"
                            size="small"
                            title="Parse the log file"
                            onClick={handleParse}
                        >
                            Parse
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.content}>
                <Box className={classes.notification}>
                    {isLoading && <LinearProgress />}
                    {isNonEmptyString(errorMessage) && (
                        <Alert severity="error">{errorMessage}</Alert>
                    )}
                </Box>
                <textarea
                    readOnly
                    className={"code ".concat(classes.text)}
                    value={text}
                />
            </DialogContent>
        </Dialog>
    );
};
