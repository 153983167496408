/**
 * Returns `true` if the specified argument is `null` or `undefined`
 * @param value The value to test
 * @returns `true` if the specified argument is `null` or `undefined`
 */
export function isNil(value: unknown): value is null | undefined {
    return value === undefined || value === null;
}

/**
 * Returns `true` if the specified argument is not `null` nor `undefined`
 * @param value The value to test
 * @returns `true` if the specified argument is not `null` nor `undefined`
 */
export function isNotNil(
    value: unknown
): value is boolean | number | string | object | symbol {
    return !isNil(value);
}

/**
 * Returns the `true` if specified argument it is a `string`
 * @param value The value to test
 * @returns  `true` if it is a string
 */
export function isString(value: unknown): value is string {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "string" || (type === "object" && value instanceof String);
}

/**
 * Returns the `true` if specified argument it is a `number`
 * @param value The value to test
 * @returns  `true` if it is a number
 */
export function isNumber(value: unknown): value is number {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "number" || (type === "object" && value instanceof Number);
}

/**
 * Returns the `true` if specified argument it is a `boolean`
 * @param value The value to test
 * @returns  `true` if it is a boolean
 */
export function isBoolean(value: unknown): value is boolean {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return (
        type === "boolean" || (type === "object" && value instanceof Boolean)
    );
}

/**
 * Returns the `true` if specified argument it is a `function`
 * @param value The value to test
 * @returns  `true` if it is a function
 */
export function isFunction(value: unknown): value is Function {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "function";
}

/**
 * Returns the `true` if specified argument it is an `object` or `function`.
 * E.g. arrays, functions, objects, regex, `new Number()`, and `new String()`
 * @param value The value to test
 * @returns  `true` if it is an `object` or `function`
 */
export function isObject(value: unknown): value is object | Function {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "object" || type === "function";
}

/**
 * Returns the `true` if specified argument it is an `object`.
 * E.g. arrays, objects, regex, `new Number()`, and `new String()`
 * @param value The value to test
 * @returns  `true` if it is an `object`
 */
export function isObjectLike(value: unknown): value is object {
    if (isNil(value)) {
        return false;
    }
    return typeof value === "object";
}

/**
 * Returns the `true` if specified argument it is a `string` and
 * the the length is not zero
 * @param value The value to test
 * @returns  `true` if it is a non empty string
 */
export function isNonEmptyString(value: unknown): value is string {
    return isString(value) && value.length > 0;
}

/**
 * Returns the `true` if specified argument it is `null`, `undefined`,
 * an empty `string`, `array` or `object`
 * @param value The value to test
 * @returns  `true` if it is a non empty string
 */
export function isEmpty(value: unknown): boolean {
    if (isNil(value)) {
        return true;
    }

    if (isString(value)) {
        return value.length < 1;
    }

    if (Array.isArray(value)) {
        return value.length < 1;
    }

    if (isObjectLike(value)) {
        return Object.keys(value).length < 1;
    }

    return false;
}

/**
 * Returns the textual representation of the specified value
 * If the value is `null` or `undefined` an empty string is returned
 * @param value The value
 * @returns The textual representation
 */
export function show(value: unknown): string {
    if (isNil(value)) {
        return "";
    }

    if (isString(value)) {
        return value.toString();
    }

    if (isNumber(value)) {
        return value.toString();
    }

    if (isBoolean(value)) {
        return value.toString();
    }

    if (Array.isArray(value)) {
        return value.length < 1
            ? ""
            : "[".concat(value.map(show).join(","), "]");
    }

    const valueToString = (value as any).toString;
    if (isFunction(valueToString)) {
        const result = valueToString.call(value);
        if (isString(result)) {
            return result;
        }
    }

    return String(value);
}

/**
 * Removes trailing whitespace or specified characters from the specified string.
 */
export function trimEnd(value: string, chars?: string): string {
    if (!isNonEmptyString(value)) {
        return "";
    }

    if (isNil(chars)) {
        return value.trimEnd();
    }

    if (!isNonEmptyString(chars)) {
        return value;
    }

    const exclude = Array.from(chars);

    const parts = Array.from(value);

    const partsLength = parts.length;

    for (let i = partsLength - 1; i >= 0; --i) {
        if (exclude.indexOf(parts[i]) < 0) {
            return parts.slice(0, i + 1).join("");
        }
    }

    return "";
}

/**
 * Creates an array of elements split into groups the length of `size`.
 * If `array` can't be split evenly, the final chunk will be the remaining
 * elements.
 *
 * @method chunk
 * @param source The array to process.
 * @param size The length of each chunk
 * @returns The new array of chunks.
 */
export function chunk<T>(source: readonly T[], size: number): T[][] {
    if (!Array.isArray(source) || source.length < 1) {
        return [];
    }

    size = Math.max(Math.floor(size), 0);

    if (Number.isNaN(size) || !Number.isFinite(size) || size < 1) {
        throw new TypeError("Invalid argument:size");
    }

    const length = source.length;

    let i = 0;

    let j = 0;

    const result = new Array(Math.ceil(length / size));

    while (i < length) {
        result[j++] = source.slice(i, (i += size));
    }

    return result;
}
