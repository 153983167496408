import { CstNode, IToken } from "chevrotain";
import moment from "moment";
import { isNil, trimEnd } from "../../helpers";
import { StationArgCode } from "../symbols";
import { StationProtocolArgument, StationProtocolMessage } from "../types";
import {
    ArgumentContext,
    ArgumentsContext,
    CommandContext,
    MessageContext,
    SequenceContext,
    stationProtocolParser,
    ValueContext,
} from "./parser";

const StationProtocolVisitorBase =
    stationProtocolParser.getBaseCstVisitorConstructorWithDefaults();

interface VisitorState {
    cmd: string | null;
    arg: string | null;
}

/**
 * Recognizes the SmartBike Station Protocol messages
 * See https://sap.github.io/chevrotain/docs/
 */
export class StationProtocolVisitor extends StationProtocolVisitorBase {
    public constructor() {
        super();

        // Performs static analysis to detect missing or redundant visitor methods
        this.validateVisitor();
    }

    /**
     * Recognizes the specified message
     * @param ctx The concrete syntax tree node
     */
    public visitMessage(ctx: CstNode): StationProtocolMessage {
        if (isNil(ctx)) {
            throw new Error("Invalid argument:ctx");
        }
        if (ctx.name !== "Message") {
            throw new Error("Invalid CST node. A `Message` node is expected.");
        }

        return this.visit(ctx);
    }

    public Message(ctx: MessageContext): StationProtocolMessage {
        const context: VisitorState = {
            cmd: null,
            arg: null,
        };
        const cmd = this.visit(ctx.Command, context);
        context.cmd = cmd;

        const seq = this.visit(ctx.Sequence, context);

        const args = this.visit(ctx.Arguments, context);
        return { cmd, rawCmd: cmd, seq, args: isNil(args) ? [] : args };
    }

    public Command(ctx: CommandContext, _state: VisitorState): string | null {
        const id = this.readID(ctx.ID);
        return id;
    }

    public Sequence(ctx: SequenceContext, _state: VisitorState): number {
        const id = this.readTEXT(ctx.TEXT);
        return isNil(id) ? NaN : parseInt(id, 10);
    }

    public Arguments(
        ctx: ArgumentsContext,
        state: VisitorState
    ): StationProtocolArgument[] {
        const arg = ctx.Argument;

        const args = Array.isArray(arg)
            ? arg.map((ctx): StationProtocolArgument => {
                  return this.visit(ctx, state);
              })
            : [];
        return args;
    }

    public Argument(
        ctx: ArgumentContext,
        state: VisitorState
    ): StationProtocolArgument {
        const arg = this.readID(ctx.ID);

        state.arg = arg;

        const value = this.visit(ctx.Value, state);

        const rawArg = isNil(arg) ? "" : arg;

        const rawValue = isNil(value) ? null : value;

        return {
            arg: rawArg,
            rawArg,
            value: rawValue,
            rawValue,
        };
    }

    public Value(ctx: ValueContext, state: VisitorState): string | null {
        let value: string | null;

        value = this.readTEXT(ctx.TEXT);
        if (!isNil(value)) {
            return value;
        }

        value = this.readFirstToken(ctx.UUID);
        if (!isNil(value)) {
            return value;
        }

        value = this.readFirstToken(ctx.DATE);
        if (!isNil(value)) {
            const parsed =
                state.arg === StationArgCode.SERVER_UTC_TIME
                    ? moment.utc(value, "MM/DD/YYYY HH-mm-ss")
                    : moment.utc(value, "DD/MM/YYYY HH-mm-ss");

            return parsed.isValid()
                ? parsed.format("YYYY-MM-DD HH:mm:ss")
                : value;
        }

        return null;
    }

    private readID(tokens: IToken[] | undefined): string | null {
        const id = this.readFirstToken(tokens);
        return isNil(id) ? null : trimEnd(id, ":");
    }

    private readTEXT(tokens: IToken[] | undefined): string | null {
        const id = this.readFirstToken(tokens);
        return isNil(id) ? null : id.trim();
    }

    private readFirstToken(tokens: IToken[] | undefined): string | null {
        if (isNil(tokens) || tokens.length < 1) {
            return null;
        }

        const head = tokens[0];
        if (isNil(head)) {
            return null;
        }

        return head.image;
    }
}

/**
 * The Station Protocol visitor
 */
export const stationProtocolVisitor = new StationProtocolVisitor();
