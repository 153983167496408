import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { isNil, isNotNil } from "../../../common";
import { BikeState, BikeStatusTag, LockState } from "../../../models";
import {
    browserTimeStampState,
    stationBikesQuery,
    stationSlotsQuery,
} from "../../../state";
import { DockBikeForm } from "./dockBikeForm";
import { SlotBike } from "./types";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        table: {
            minWidth: "850px",
        },
        state: {
            display: "inline-block",
            marginRight: "8px",
            float: "left",
        },
        bike: {
            borderColor: "white",
            borderStyle: "solid",
            borderWidth: "1px",
            width: "30px",
            height: "30px",
            textAlign: "center",
            padding: "0",
            lineHeight: "30px",
        },
        riding: {
            color: "white",
            backgroundColor: "#2e7d32",
        },
        pending: {
            color: "white",
            backgroundColor: red[800],
        },
        broken: {
            color: "white",
            backgroundColor: red[800],
            borderColor: red[800],
        },
        ride: {
            display: "inline-block",
            width: "220px",
        },
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

export interface StationBikeProps {
    classes: ClassDefs;
    bike: BikeState;
    slots: LockState[];
    setSelectedSlot: (value: SlotBike) => void;
    isDockOpen: boolean;
    setIsDockOpen: (value: boolean) => void;
    browserTimeStamp: number;
}

function BikeListItem({
    classes,
    bike,
    slots,
    setSelectedSlot,
    isDockOpen,
    setIsDockOpen,
}: StationBikeProps) {
    const { station, ride } = bike;

    const hasRide = isNotNil(ride) && ride.bikeRideID > 0;

    const hasStation = isNotNil(station) && station.stationID > 0;

    const subscriber = hasRide ? ride?.subscriber : null;

    const checkout = hasRide ? moment.utc(ride?.checkoutTime).local() : null;

    const elapsed = isNil(checkout) ? "" : checkout.fromNow();

    const pending = isNil(checkout)
        ? false
        : moment().diff(checkout, "minute") < 5;

    const isActive = bike.bikeStatus === BikeStatusTag.Active;

    function handleDock() {
        const found = slots.find(function (slot) {
            return slot.isEmpty;
        });

        if (isNotNil(found)) {
            setSelectedSlot({
                slotNumber: found.slotNumber,
                bikeRFID: bike.bikeRFID,
            });
            setIsDockOpen(true);
        }
    }

    return (
        <TableRow>
            <TableCell className="code">{bike.bikeRFID}</TableCell>
            <TableCell>
                <Box className={classes.state}>
                    {hasStation ? (
                        <Icon className={classes.bike} title="Docked">
                            pedal_bike
                        </Icon>
                    ) : hasRide ? (
                        <Icon
                            className={classes.bike.concat(
                                " ",
                                pending ? classes.pending : classes.riding
                            )}
                            title="On ride"
                        >
                            directions_bike
                        </Icon>
                    ) : (
                        <Icon className={classes.bike} title="Unknown">
                            help_outline
                        </Icon>
                    )}
                </Box>
                <Box
                    className={classes.state.concat(
                        " ",
                        bike.brokenCount > 0
                            ? `${classes.bike} ${classes.broken}`
                            : ""
                    )}
                    title="Broken count"
                >
                    {bike.brokenCount > 0 ? bike.brokenCount.toFixed() : ""}
                </Box>
            </TableCell>
            <TableCell className="code">
                <Box className={isActive ? "" : classes.broken}>
                    {bike.bikeStatus}
                </Box>
            </TableCell>
            <TableCell className="code">
                {hasStation
                    ? `${
                          station?.stationCode
                      } slot ${station?.slotNumber.toFixed()}`
                    : "NONE"}
            </TableCell>
            <TableCell className="code">
                <Box className={classes.ride}>
                    {hasRide ? (
                        <span>
                            {`CID\u00A0${subscriber?.accountID} RID\u00A0${ride?.bikeRideID}`}
                            <br />
                            {`Checkout ${elapsed}`}
                        </span>
                    ) : (
                        "NONE"
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        title="Return the bike to the station"
                        disabled={hasStation || isDockOpen}
                        onClick={handleDock}
                    >
                        Dock
                    </Button>
                </Box>
            </TableCell>
        </TableRow>
    );
}

export function BikeList() {
    const classes = useStyles();

    const slots = useRecoilValue(stationSlotsQuery);

    const bikes = useRecoilValue(stationBikesQuery);

    const browserTimeStamp = useRecoilValue(browserTimeStampState);

    const [selectedSlot, setSelectedSlot] = useState<SlotBike>(function () {
        return {
            slotNumber: 0,
            bikeRFID: "",
        };
    });

    const [isDockOpen, setIsDockOpen] = useState(false);

    return (
        <TableContainer>
            <DockBikeForm
                isOpen={isDockOpen}
                setIsOpen={setIsDockOpen}
                slot={selectedSlot}
            />

            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Bike RFID</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Station</TableCell>
                        <TableCell>Ride</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bikes.map((bike) => (
                        <BikeListItem
                            key={`bike_${bike.bikeRFID}`}
                            classes={classes}
                            bike={bike}
                            slots={slots}
                            browserTimeStamp={browserTimeStamp}
                            setSelectedSlot={setSelectedSlot}
                            isDockOpen={isDockOpen}
                            setIsDockOpen={setIsDockOpen}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
