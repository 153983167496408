import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

export function LoadingIndicator() {
    return (
        <Box padding="8px">
            <Box paddingTop="16px">Loading, one moment please...</Box>
            <Box paddingTop="16px">
                <LinearProgress />
            </Box>
        </Box>
    );
}
