import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container } from "inversify";
import React from "react";
import ReactDOM from "react-dom";
import { MutableSnapshot, RecoilRoot } from "recoil";
import { App } from "./app";
import { registerServices } from "./bootstrap";
import { ErrorBoundary, LoadingIndicator } from "./components";
import { ConfigVar } from "./config";
import { Ambient, AmbientProvider, AmbientTypeTag } from "./context";
import "./index.css";
import { ConfigStore, ConfigStoreTypeTag } from "./services/configStore";
import { StationEmulator, StationEmulatorTypeTag } from "./services/station";
import * as serviceWorker from "./serviceWorker";
import { defaultTheme } from "./themes";

/**
 * Loads the static configuration settings
 */
function loadStaticConfig(container: Container): void {
    const config = container.get<ConfigStore>(ConfigStoreTypeTag);

    config.set(ConfigVar.NODE_ENV, process.env.NODE_ENV);

    config.set(
        ConfigVar.API_ENDPOINT_URL,
        process.env.REACT_APP_API_ENDPOINT_URL
    );
}

/**
 * Registers and initializes the services
 */
async function initServices(): Promise<Container> {
    const container = registerServices();

    loadStaticConfig(container);

    const config = container.get<ConfigStore>(ConfigStoreTypeTag);

    {
        const service = container.get<StationEmulator>(StationEmulatorTypeTag);

        await service.init({
            endpointUrl: config.get(ConfigVar.API_ENDPOINT_URL),
        });
    }

    return container;
}

/**
 * Initializes the local state
 * See {@link https://recoiljs.org/docs/guides/persistence#restoring-state}
 */
function initializeState(
    _ambient: Ambient
): (_snapshot: MutableSnapshot) => void {
    return function initializeState(_snapshot: MutableSnapshot) {};
}

/** Renders the app */
function runApp(container: Container) {
    const ambient = container.get<Ambient>(AmbientTypeTag);

    ReactDOM.render(
        <React.StrictMode>
            <AmbientProvider ambient={ambient}>
                <MuiThemeProvider theme={defaultTheme}>
                    <CssBaseline />
                    <RecoilRoot initializeState={initializeState(ambient)}>
                        <ErrorBoundary>
                            <React.Suspense fallback={<LoadingIndicator />}>
                                <App />
                            </React.Suspense>
                        </ErrorBoundary>
                    </RecoilRoot>
                </MuiThemeProvider>
            </AmbientProvider>
        </React.StrictMode>,
        document.getElementById("root")
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

initServices()
    .then(runApp)
    .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    });
