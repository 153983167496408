import ow from "ow";
import { StationSpec } from "../models";
import { ConfigStore } from "../services/configStore";
import { Logger } from "../services/logger";
import { StationEmulator } from "../services/station";

/**
 * Exposes the execution context
 */
export class Ambient {
    private readonly _logger: Logger;

    private readonly _config: ConfigStore;

    private readonly _station: StationEmulator;

    private readonly _setStationSpec: (env: string, stationID: number) => void;

    private readonly _getStationSpec: () => StationSpec;

    private _stationSpec: StationSpec;

    /**
     * Constructs an instance of {@link Ambient}
     * @param logger The logger
     * @param config The config store
     * @param station The station service
     */
    public constructor(
        logger: Logger,
        config: ConfigStore,
        station: StationEmulator
    ) {
        ow(logger, ow.object);
        ow(config, ow.object);
        ow(station, ow.object);

        this._logger = logger;

        this._config = config;

        this._station = station;

        function setStationSpec(
            this: Ambient,
            env: string,
            stationID: number
        ): void {
            ow(env, ow.string.nonEmpty);
            ow(stationID, ow.number.finite);
            this._stationSpec = { env, stationID };
        }

        this._setStationSpec = setStationSpec.bind(this);

        function getStationSpec(this: Ambient): StationSpec {
            return this._stationSpec;
        }

        this._getStationSpec = getStationSpec.bind(this);

        this._stationSpec = { env: "", stationID: 0 };
    }

    /** The logger */
    public get logger(): Logger {
        return this._logger;
    }

    /** The configuration store */
    public get config(): ConfigStore {
        return this._config;
    }

    /** The station emulator API */
    public get station(): StationEmulator {
        return this._station;
    }

    /** The current station spec */
    public get stationSpec(): StationSpec {
        return this._stationSpec;
    }

    /** Returns a function that sets the current station spec */
    public get setStationSpec(): (env: string, stationID: number) => void {
        return this._setStationSpec;
    }

    /** Returns a function that gets the current station spec */
    public get getStationSpec(): () => StationSpec {
        return this._getStationSpec;
    }
}

export const AmbientTypeTag = Symbol.for("Ambient");
