/**
 * Provides a function for fetching resources
 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 */
export interface FetchProvider {
    /**
     * Returns a function that, when invoked, starts the process of fetching a resource from
     * the network, returning a promise which is fulfilled once
     * the response is available
     * See https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
     */
    useFetch(): (
        resource: RequestInfo,
        init?: RequestInit
    ) => Promise<Response>;
}

export const FetchProviderTypeTag = Symbol.for(
    "ed6c56f8-b31a-4c8e-b5cf-8bee5534805b"
);
