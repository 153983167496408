import { isNonEmptyString } from "../common";

export function setDocumentTitle(section: string): void {
    const prefix = "SmartBike Station";

    if (isNonEmptyString(section)) {
        document.title = section.concat(" - ", prefix);
    } else {
        document.title = prefix;
    }
}
