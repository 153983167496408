import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { useState } from "react";
import { CardReaderForm } from "./cardReaderForm";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        action: {
            marginLeft: "8px",
        },
        icon: {
            marginRight: "8px",
        },
    });
});

export function CardReader() {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    function handleOpen() {
        setIsOpen(true);
    }

    return (
        <Box>
            <Button
                className={classes.action}
                color="inherit"
                size="small"
                title="Simulate a card swipe"
                onClick={handleOpen}
            >
                <CreditCardIcon className={classes.icon} />
                Card Reader
            </Button>
            {isOpen && <CardReaderForm isOpen={isOpen} setIsOpen={setIsOpen} />}
        </Box>
    );
}
