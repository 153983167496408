import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        root: {
            padding: "16px",
            maxWidth: "460px",
        },
        section: {
            paddingTop: "16px",
            paddingBottom: "16px",
            textAlign: "center",
        },
        icon: {
            marginLeft: "16px",
        },
    });
});

export function LoginForm() {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Box className={classes.section}>
                <Typography variant="body1">
                    Please, press the button below to log in to your account.
                </Typography>
                <br />
                <Typography variant="body2">
                    A BKO (dev or uat) account is required.
                </Typography>
            </Box>
            <Box className={classes.section}>
                <form action="/auth/microsoft/authorize" method="GET">
                    <Button color="primary" variant="contained" type="submit">
                        Login
                        <Icon className={classes.icon}>login</Icon>
                    </Button>
                </form>
            </Box>
        </Paper>
    );
}
