import { atom } from "recoil";
import { emptyStationSpec, StationSpec } from "../models";

/**
 * The state atom keys
 * See {@link https://recoiljs.org/docs/introduction/core-concepts}
 */
export enum StateAtomKey {
    /** The current station */
    stationSpec = "stationSpecState",

    /** The browser timestamp */
    browserTimeStamp = "browserTimeStampState",

    /** The station screen event timestamp */
    screenTimeStamp = "screenTimeStampState",

    /** The station state event timestamp */
    stateTimeStamp = "stateTimeStampState",

    /** The station slot event timestamp */
    slotTimeStamp = "slotTimeStampState",

    /** The team event timestamp */
    teamTimeStamp = "teamTimeStampState",

    /** Station reboot requested */
    rebootRequested = "rebootRequested",
}

export const stationSpecState = atom<StationSpec>({
    key: StateAtomKey.stationSpec,
    default: emptyStationSpec(),
});

export const browserTimeStampState = atom<number>({
    key: StateAtomKey.browserTimeStamp,
    default: 0,
});

export const screenTimeStampState = atom<number>({
    key: StateAtomKey.screenTimeStamp,
    default: 0,
});

export const stateTimeStampState = atom<number>({
    key: StateAtomKey.stateTimeStamp,
    default: 0,
});

export const slotTimeStampState = atom<number>({
    key: StateAtomKey.slotTimeStamp,
    default: 0,
});

export const teamTimeStampState = atom<number>({
    key: StateAtomKey.teamTimeStamp,
    default: 0,
});

export const rebootRequestedState = atom<boolean>({
    key: StateAtomKey.rebootRequested,
    default: false,
});
