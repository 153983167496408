import { Subscription } from "rxjs";
import {
    BikeCheckoutPayload,
    BikeConfig,
    BikeState,
    CardReaderInput,
    CardReaderPayload,
    DockBikePayload,
    FileSpec,
    KeyPadInput,
    KeyPadPayload,
    LockState,
    NetworkInput,
    NetworkPayload,
    SlotState,
    StationEvent,
    StationRebootPayload,
    StationSpec,
    StationState,
    StationSummary,
    TeamMember,
    UserProfile,
} from "../../models";

/** Options to initialize a {@link StationEmulator} */
export interface StationEmulatorOptions {
    /** The station emulator API URL */
    endpointUrl: string;
}

/**
 * Exposes methods to interact with the station emulator API
 */
export interface StationEmulator {
    /** Initializes the service */
    init(options: StationEmulatorOptions): Promise<void>;

    /** Returns the current user profile */
    getUser(): Promise<UserProfile>;

    /** Returns the configured stations*/
    getStations(): Promise<StationSummary[]>;

    /** Returns the team members connected to the specified station */
    getConnectedMembers(station: StationSpec): Promise<TeamMember[]>;

    /** Returns the station screen image URL */
    getScreenImageUrl(station: StationSpec, nonce: number): string;

    /** Returns the station details */
    getStationSummary(station: StationSpec): Promise<StationSummary>;

    /** Returns the station state */
    getStationState(station: StationSpec): Promise<StationState>;

    /** Returns the configured bikes */
    getConfigBikes(station: StationSpec): Promise<BikeConfig[]>;

    /** Returns the station log files */
    getLogFiles(station: StationSpec): Promise<FileSpec[]>;

    /** Returns the specified log file content*/
    getLogFileContent(station: StationSpec, fileName: string): Promise<string>;

    /** Returns the configured bikes state */
    getBikes(station: StationSpec): Promise<BikeState[]>;

    /** Returns the slot locks state */
    getLocks(station: StationSpec): Promise<LockState[]>;

    /** Returns the specified slot lock state */
    getLock(station: StationSpec, slotNumber: number): Promise<LockState>;

    /** Returns the specified slot state */
    getSlot(station: StationSpec, slotNumber: number): Promise<SlotState>;

    /** Removes the bike from the specified slot */
    removeGrantedBike(
        station: StationSpec,
        slotNumber: number
    ): Promise<BikeCheckoutPayload>;

    /** Docks a bike in the specified slot */
    dockBike(
        station: StationSpec,
        slotNumber: number,
        bikeRFID: string
    ): Promise<DockBikePayload>;

    /** Simulates a card reader swipe */
    simulateCardSwipe(
        station: StationSpec,
        input: CardReaderInput
    ): Promise<CardReaderPayload>;

    /** Simulates a keypad input */
    simulateKeyPadInput(
        station: StationSpec,
        input: KeyPadInput
    ): Promise<KeyPadPayload>;

    /** Updates the network state */
    updateNetwork(
        station: StationSpec,
        input: NetworkInput
    ): Promise<NetworkPayload>;

    /** Request a station reboot */
    rebootStation(station: StationSpec): Promise<StationRebootPayload>;

    /**
     * Subscribe to the station event stream
     * @param callback The callback invoked on event reception
     */
    subscribe(
        station: StationSpec,
        callback: (value: StationEvent) => void
    ): Subscription;
}

/** The {@link StationEmulator} interface type tag */
export const StationEmulatorTypeTag = Symbol.for(
    "2a0aa56a-0a38-44d7-880c-a0f2dcd96c1c"
);
