import Avatar from "@material-ui/core/Avatar";
import { amber, deepOrange, deepPurple, pink } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import { isNil } from "../../../common";
import { TeamMember, UserProfile } from "../../../models";

export function makeMemberStyleDefs(theme: Theme) {
    return {
        member: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        member1: {
            color: theme.palette.getContrastText(pink[500]),
            backgroundColor: pink[500],
        },
        member2: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
        },
        member3: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        member4: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
    };
}

export interface MemberAvatarClassDefs {
    member: string;
    member1: string;
    member2: string;
    member3: string;
    member4: string;
}

export interface MemberAvatarProps {
    key: string | number;
    member: TeamMember;
    classes: MemberAvatarClassDefs;
    className: string;
}

export function MemberAvatar({
    key,
    member,
    classes,
    className,
}: MemberAvatarProps) {
    const username = member.username;
    return (
        <Avatar
            key={key}
            alt={username}
            title={username}
            className={classes.member.concat(" ", className)}
        >
            {username.slice(0, 1).toUpperCase()}
        </Avatar>
    );
}

export function makeMemberAvatars(
    user: UserProfile,
    members: TeamMember[],
    classes: MemberAvatarClassDefs
): JSX.Element[] {
    if (members.length < 2) {
        return [];
    }

    const username = user.username;

    const current = members.find(function (member) {
        return member.username === username;
    });

    if (isNil(current)) {
        return [];
    }

    const result: JSX.Element[] = [
        <MemberAvatar
            key="member_1"
            member={current}
            classes={classes}
            className={classes.member1}
        />,
    ];

    const others = members.filter(function (member) {
        return member.username !== username;
    });

    if (others.length < 1) {
        return result;
    }

    let member = others[0];

    result.push(
        <MemberAvatar
            key="member_2"
            member={member}
            classes={classes}
            className={classes.member2}
        />
    );

    if (others.length < 2) {
        return result;
    }

    member = others[1];

    result.push(
        <MemberAvatar
            key="member_3"
            member={member}
            classes={classes}
            className={classes.member3}
        />
    );

    if (others.length < 3) {
        return result;
    }

    member = others[2];

    result.push(
        <MemberAvatar
            key="member_4"
            member={member}
            classes={classes}
            className={classes.member4}
        />
    );

    for (let i = 3; i < others.length; ++i) {
        member = others[i];
        result.push(
            <MemberAvatar
                key={`member_${i + 2}`}
                member={member}
                classes={classes}
                className=""
            />
        );
    }

    return result;
}

export interface MemberListItemProps {
    key: string | number;
    member: TeamMember;
    classes: MemberAvatarClassDefs;
    className: string;
}

export function MemberListItem({
    key,
    member,
    classes,
    className,
}: MemberListItemProps) {
    const username = member.username;
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    key={key}
                    alt={username}
                    title={username}
                    className={classes.member.concat(" ", className)}
                >
                    {username.slice(0, 1).toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={username} />
        </ListItem>
    );
}

export function makeMemberListItems(
    user: UserProfile,
    members: TeamMember[],
    classes: MemberAvatarClassDefs
): JSX.Element[] {
    const found = new Set<string>();

    const uniqueMembers: TeamMember[] = [];

    for (const member of members) {
        const id = member.userID;
        if (!found.has(id)) {
            found.add(id);
            uniqueMembers.push(member);
        }
    }

    const username = user.username;

    const current = uniqueMembers.find(function (member) {
        return member.username === username;
    });

    if (isNil(current)) {
        return [];
    }

    const result: JSX.Element[] = [
        <MemberListItem
            key="member_1"
            member={current}
            classes={classes}
            className={classes.member1}
        />,
    ];

    const others = uniqueMembers.filter(function (member) {
        return member.username !== username;
    });

    if (others.length < 1) {
        return result;
    }

    let member = others[0];

    result.push(
        <MemberListItem
            key="member_2"
            member={member}
            classes={classes}
            className={classes.member2}
        />
    );

    if (others.length < 2) {
        return result;
    }

    member = others[1];

    result.push(
        <MemberListItem
            key="member_3"
            member={member}
            classes={classes}
            className={classes.member3}
        />
    );

    if (others.length < 3) {
        return result;
    }

    member = others[2];

    result.push(
        <MemberListItem
            key="member_4"
            member={member}
            classes={classes}
            className={classes.member4}
        />
    );

    for (let i = 3; i < others.length; ++i) {
        member = others[i];
        result.push(
            <MemberListItem
                key={`member_${i + 2}`}
                member={member}
                classes={classes}
                className=""
            />
        );
    }

    return result;
}
