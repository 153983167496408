import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useRecoilValue } from "recoil";
import { stationScreenUrlQuery } from "../../../state";

const useStyles = makeStyles(function (theme: Theme) {
    return createStyles({
        image: {
            border: "#808080 solid 1px",
            width: "600px",
            height: "800px",
            [theme.breakpoints.down(1480)]: {
                width: "405px",
                height: "540px",
            },
            [theme.breakpoints.down(1380)]: {
                width: "318px",
                height: "424px",
            },
            [theme.breakpoints.down(1200)]: {
                width: "180px",
                height: "240px",
            },
        },
    });
});

export function StationDisplay() {
    const classes = useStyles();

    const stationScreenUrl = useRecoilValue(stationScreenUrlQuery);

    return (
        <Box>
            <img
                className={classes.image}
                src={stationScreenUrl}
                alt="Station Screen"
            />
        </Box>
    );
}
