import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { UserProfile } from "../models";

export function HomeButton() {
    return (
        <IconButton edge="start" color="inherit" title="Home">
            <Link
                title="Home"
                component={RouterLink}
                to="/home"
                color="inherit"
            >
                <Icon>home</Icon>
            </Link>
        </IconButton>
    );
}

export interface ToolbarClassDefs {
    rightPanel: string;
    rightIcon: string;
    action: string;
}

export interface ToolbarProps {
    user: UserProfile;
    classes: ToolbarClassDefs;
}

export function UserToolbar({ user, classes }: ToolbarProps) {
    const username = user.username.split("@")[0] ?? "";

    return (
        <Box className={classes.rightPanel} title={user.username}>
            <Icon className={classes.rightIcon}>account_circle</Icon>
            <Box
                display={{
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "none",
                    xl: "inline",
                }}
            >
                {username}
            </Box>
        </Box>
    );
}

export function SessionToolbar({ classes }: ToolbarProps) {
    return (
        <Box className={classes.rightPanel}>
            <form action="/auth/microsoft/logout" method="GET">
                <Button
                    className={classes.action}
                    color="inherit"
                    title="Close the current session"
                    type="submit"
                    size="small"
                >
                    <Icon className={classes.rightIcon}>
                        power_settings_new
                    </Icon>
                    Logout
                </Button>
            </form>
        </Box>
    );
}
