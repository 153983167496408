import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ErrorBoundary, LoadingIndicator } from "../../../components";
import { browserTimeStampState, rebootRequestedState } from "../../../state";
import { BikeList } from "./bikeList";
import { SlotList } from "./slotList";
import { StationDisplay } from "./stationDisplay";
import { StationState } from "./stationState";
import { StationSummary } from "./stationSummary";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        root: {
            marginTop: "4px",
            marginBottom: "16px",
        },
        summary: {
            padding: "16px",
            minHeight: "44px",
        },
        state: {
            padding: "16px",
            minHeight: "44px",
        },
        display: {
            padding: "16px",
        },
        slots: {
            padding: "16px",
            minWidth: "880px",
            minHeight: "340px",
        },
        bikes: {
            padding: "16px",
            minWidth: "880px",
            minHeight: "370px",
        },
        reboot: {
            padding: "16px",
            minHeight: "84px",
        },
        alert: {},
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

interface RunningStationPanelProps {
    classes: ClassDefs;
}

function RunningStationPanel({ classes }: RunningStationPanelProps) {
    return (
        <Fragment>
            <Grid item>
                <Paper className={classes.summary}>
                    <React.Suspense fallback={<CircularProgress size="16px" />}>
                        <StationSummary />
                    </React.Suspense>
                </Paper>
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <Paper className={classes.display}>
                            <StationDisplay />
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Paper className={classes.state}>
                                    <React.Suspense
                                        fallback={
                                            <CircularProgress size="16px" />
                                        }
                                    >
                                        <StationState />
                                    </React.Suspense>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.slots}>
                                    <Typography
                                        variant="h6"
                                        color="textSecondary"
                                    >
                                        Slots
                                    </Typography>
                                    <React.Suspense
                                        fallback={<CircularProgress />}
                                    >
                                        <SlotList />
                                    </React.Suspense>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.bikes}>
                                    <Typography
                                        variant="h6"
                                        color="textSecondary"
                                    >
                                        Bikes
                                    </Typography>
                                    <React.Suspense
                                        fallback={<CircularProgress />}
                                    >
                                        <BikeList />
                                    </React.Suspense>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

interface RebootingStationPanelProps {
    classes: ClassDefs;
}

function formatRebootMessage(started: number, now: number): string {
    const elapsed = moment.duration(now - started, "ms");

    return `The station is rebooting, one moment please (elapsed ${elapsed.minutes()} min ${elapsed.seconds()} s)...`;
}

function RebootingStationPanel({ classes }: RebootingStationPanelProps) {
    const now = useRecoilValue(browserTimeStampState);

    const [started] = useState(now);

    useEffect(function () {
        const timer = setTimeout(function reload() {
            window.location.reload();
        }, 30 * 1000);
        return function stopTimer() {
            clearInterval(timer);
        };
    }, []);

    const message = formatRebootMessage(started, now);

    return (
        <Grid item>
            <Paper className={classes.reboot}>
                <Box className={classes.alert}>
                    <Alert variant="standard" severity="error">
                        <Box>{message}</Box>
                    </Alert>
                </Box>
                <Box>
                    <LinearProgress color="secondary" />
                </Box>
            </Paper>
        </Grid>
    );
}

export function StationPanel() {
    const classes = useStyles();
    const isRebootRequested = useRecoilValue(rebootRequestedState);

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            alignItems="stretch"
            spacing={1}
        >
            <ErrorBoundary>
                <React.Suspense fallback={<LoadingIndicator />}>
                    {isRebootRequested ? (
                        <RebootingStationPanel classes={classes} />
                    ) : (
                        <RunningStationPanel classes={classes} />
                    )}
                </React.Suspense>
            </ErrorBoundary>
        </Grid>
    );
}
