/**
 * The station lock state values
 */
export enum LockStateTag {
    PRE_INIT = "PRE_INIT",
    INIT = "INIT",
    READY = "READY",
    CHECK_IF_ID = "CHECK_IF_ID",
    OPEN_LOCK = "OPEN_LOCK",
    CLOSE_LOCK = "CLOSE_LOCK",
    DELAY_OPEN = "DELAY_OPEN",
    SCAN_ID = "SCAN_ID",
    VERIFY_ID = "VERIFY_ID",
    RELEASE_BIKE = "RELEASE_BIKE",
    UNLOCKED_BIKE = "UNLOCKED_BIKE",
    KEEP_BIKE = "KEEP_BIKE",
    CHECK_REMOVAL = "CHECK_REMOVAL",
    CHECK_INFO = "CHECK_INFO",
    TRY_CLOSE = "TRY_CLOSE",
    RE_SCAN_ID = "RE_SCAN_ID",
    RECOVER_ID = "RECOVER_ID",
    INIT_CLOSE = "INIT_CLOSE",
    TRY_INIT = "TRY_INIT",
    LOCKED_BIKE = "LOCKED_BIKE",
    BROKEN_BIKE = "BROKEN_BIKE",
    START_SUP = "START_SUP",
    END_SUP = "END_SUP",
    BOOKED_BIKE = "END_SUP",
    BOOKED_LOCK = "BOOKED_LOCK",
    ERROR_INIT = "ERROR_INIT",
    ERROR_INFO = "ERROR_INFO",
    ERROR_CLOSE = "ERROR_CLOSE",
    ERROR_NEVER_ID = "ERROR_NEVER_ID",
    ERROR_NOK_ID = "ERROR_NOK_ID",
    ERROR_LOCK_STATE_UNDEFINED = "ERROR_LOCK_STATE_UNDEFINED",
}

/**
 * State of a bike station slot lock
 */
export interface LockState {
    slotNumber: number;

    slotState: string;

    slotStatus: string;

    lockStatus: string;

    lockState: string;

    isActive: boolean;

    isInitialized: boolean;

    isOpen: boolean;

    isEmpty: boolean;

    isSupervisorMode: boolean;

    actionID: number;

    bikeRFID: string | null;

    bikeCheckOutStatus: string;
}
