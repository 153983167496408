import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { blue } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { BikeStationIcon } from "../../../components";
import { StationSummary } from "../../../models";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        root: {
            minWidth: "320px",
            marginRight: "16px",
            marginBottom: "16px",
        },
        avatar: {
            backgroundColor: blue[500],
        },
        label: {
            display: "inline-block",
            minWidth: "100px",
            marginRight: "8px",
            fontWeight: "bold",
        },
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

interface FieldValueProps {
    fieldName: string;
    fieldValue: string | number;
    classes: ClassDefs;
}

function FieldValue({ fieldName, fieldValue, classes }: FieldValueProps) {
    return (
        <Box>
            <Box className={classes.label}>{fieldName}</Box>
            <Box component="code">{fieldValue}</Box>
        </Box>
    );
}

export interface StationItemProps {
    station: StationSummary;
}

export function StationItem({ station }: StationItemProps) {
    const classes = useStyles();

    const { env, cityID, cityName, stationID, stationCode, stationName } =
        station;

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <BikeStationIcon />
                    </Avatar>
                }
                title={`Station ${stationCode}`}
                titleTypographyProps={{ variant: "h6" }}
                subheader={`${cityName} (${env})`}
            />
            <Divider />
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <FieldValue
                            fieldName="Environment"
                            fieldValue={env}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item>
                        <FieldValue
                            fieldName="City Name"
                            fieldValue={cityName}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item>
                        <FieldValue
                            fieldName="City ID"
                            fieldValue={cityID}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item>
                        <FieldValue
                            fieldName="Station Name"
                            fieldValue={stationName}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item>
                        <FieldValue
                            fieldName="Station Code"
                            fieldValue={stationCode}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item>
                        <FieldValue
                            fieldName="Station ID"
                            fieldValue={stationID}
                            classes={classes}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                <Button>
                    <Link
                        title="View the station"
                        component={RouterLink}
                        to={`/env/${station.env}/station/${station.stationID}`}
                    >
                        View
                    </Link>
                </Button>
            </CardActions>
        </Card>
    );
}
