import { isNil, isNonEmptyString } from "../helpers";
import {
    stationProtocolLexer,
    stationProtocolParser,
    stationProtocolVisitor,
} from "./grammar";
import {
    getKnownValueById,
    stationArguments,
    stationCommands,
} from "./symbols";
import { StationProtocolMessage } from "./types";

/**
 * Parses the specified message
 * Throws if the message cannot be parsed.
 *
 * @param message The protocol message
 */
export function parseMessage(message: string): StationProtocolMessage {
    if (!isNonEmptyString(message)) {
        throw new Error("Invalid argument:text");
    }

    const lexerResult = stationProtocolLexer.tokenize(message);

    const parserResult = stationProtocolParser.parseMessage(lexerResult);

    const result = stationProtocolVisitor.visitMessage(parserResult);

    return result;
}

/**
 * Parses the specified message and translates the command and argument ids
 * Throws if the message cannot be parsed.
 *
 * @param messageText The protocol message
 */
export function parseAndIdentifyMessage(
    messageText: string
): StationProtocolMessage {
    const message = parseMessage(messageText);

    const { rawCmd, args } = message;

    const commandName = stationCommands.get(rawCmd);
    if (!isNil(commandName)) {
        message.cmd = commandName;
    }

    if (Array.isArray(args)) {
        for (const arg of args) {
            const rawArg = arg.rawArg;
            const argName = stationArguments.get(rawArg);
            if (!isNil(argName)) {
                arg.arg = argName;
                const argValue = getKnownValueById(rawArg, arg.rawValue);
                if (!isNil(argValue)) {
                    arg.value = argValue;
                }
            }
        }
    }

    return message;
}

/**
 * Parses the specified message and translates the command and argument ids
 * Returns `null` if the message cannot be parsed.
 *
 * @param message The protocol message
 */
export function tryParseAndIdentifyMessage(
    message: string
): StationProtocolMessage | null {
    if (!isNonEmptyString(message)) {
        return null;
    }
    try {
        return parseAndIdentifyMessage(message);
    } catch {
        return null;
    }
}

/**
 * Parses the specified messages and translates the command and argument ids
 *
 * @param messages The protocol messages
 */
export function tryParseAndIdentifyMessages(
    messages: string[]
): (StationProtocolMessage | null)[] {
    const result: (StationProtocolMessage | null)[] = [];

    if (!Array.isArray(messages) || messages.length < 1) {
        return result;
    }

    for (const message of messages) {
        result.push(tryParseAndIdentifyMessage(message));
    }

    return result;
}
