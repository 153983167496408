import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useRecoilValue } from "recoil";
import { stationSummaryQuery } from "../../../state";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        label: {
            display: "inline-block",
            marginRight: "8px",
            fontWeight: "bold",
        },
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

interface FieldValueProps {
    fieldName: string;
    fieldValue: string | number;
    classes: ClassDefs;
}

function FieldValue({ fieldName, fieldValue, classes }: FieldValueProps) {
    return (
        <Box>
            <Box className={classes.label}>{fieldName}</Box>
            <Box component="code">{fieldValue}</Box>
        </Box>
    );
}

export function StationSummary() {
    const classes = useStyles();

    const { env, cityID, cityName, stationID, stationCode, stationName } =
        useRecoilValue(stationSummaryQuery);

    return (
        <Grid container direction="row" spacing={4}>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Environment"
                    fieldValue={env}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="City Name"
                    fieldValue={cityName}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="City ID"
                    fieldValue={cityID}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Station Code"
                    fieldValue={stationCode}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Station ID"
                    fieldValue={stationID}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Station Name"
                    fieldValue={stationName}
                />
            </Grid>
        </Grid>
    );
}
