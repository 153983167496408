import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { isNonEmptyString } from "../../../common";
import { browserTimeStampState, stationStateQuery } from "../../../state";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        label: {
            display: "inline-block",
            marginRight: "8px",
            fontWeight: "bold",
        },
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

interface FieldValueProps {
    fieldName: string;
    fieldValue: string | number;
    classes: ClassDefs;
}

function FieldValue({ fieldName, fieldValue, classes }: FieldValueProps) {
    return (
        <Box>
            <Box className={classes.label}>{fieldName}</Box>
            <Box component="code">{fieldValue}</Box>
        </Box>
    );
}

interface KeepAliveValueProps extends FieldValueProps {
    browserTimeStamp: number;
}

function formatKeepAlive(value: string | number): string {
    if (!isNonEmptyString(value)) {
        return "";
    }

    const ts = moment.utc(value);

    return ts.fromNow();
}

function KeepAliveValue({
    fieldName,
    fieldValue,
    classes,
}: KeepAliveValueProps) {
    const fieldText = formatKeepAlive(fieldValue);

    return (
        <Box>
            <Box className={classes.label}>{fieldName}</Box>
            <Box component="code">{fieldText}</Box>
        </Box>
    );
}

export function StationState() {
    const classes = useStyles();

    const {
        stationState,
        connectivityState,
        isNetworkDisabled,
        lastKeepAliveResponseDate,
    } = useRecoilValue(stationStateQuery);

    const browserTimeStamp = useRecoilValue(browserTimeStampState);

    return (
        <Grid container direction="row" spacing={4}>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="State"
                    fieldValue={stationState}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Connectivity"
                    fieldValue={connectivityState}
                />
            </Grid>
            <Grid item>
                <FieldValue
                    classes={classes}
                    fieldName="Network"
                    fieldValue={isNetworkDisabled ? "DISABLED" : "ENABLED"}
                />
            </Grid>
            <Grid item>
                <KeepAliveValue
                    classes={classes}
                    fieldName="Keep Alive"
                    fieldValue={lastKeepAliveResponseDate}
                    browserTimeStamp={browserTimeStamp}
                />
            </Grid>
        </Grid>
    );
}
