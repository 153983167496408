import Grid from "@material-ui/core/Grid";
import { useRecoilValue } from "recoil";
import { stationsQuery } from "../../../state";
import { StationItem } from "./stationItem";

export function StationList() {
    const stations = useRecoilValue(stationsQuery);

    return (
        <Grid container direction="row" alignItems="flex-start" spacing={1}>
            {stations.map((station) => (
                <Grid item key={`station_${station.env}_${station.stationID}`}>
                    <StationItem station={station} />
                </Grid>
            ))}
        </Grid>
    );
}
