import React from "react";
import { invariant } from "ts-invariant";
import { isNotNil } from "../common";
import { Ambient } from "./ambient";
import { getAmbientContext } from "./ambientContext";

/**
 * Access the runtime environment context
 */
export function useAmbient(): Ambient {
    const { ambient } = React.useContext(getAmbientContext());
    invariant(
        isNotNil(ambient),
        "No Ambient instance can be found. Please ensure that you " +
            "have called `AmbientProvider` higher up in your tree."
    );
    return ambient as Ambient;
}
