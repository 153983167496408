import { isNil } from "../helpers";

/**
 * The SmartBike Station Protocol commands
 * See https://cci-clearchannel.atlassian.net/wiki/spaces/SB/pages/99321647/Station-BKO+Communication+Parameter+definitions
 */

/**
 * The codes for station commands.
 */
export enum StationCommandCode {
    /**
     * Command used for communicating the authentication request.
     */
    AUTHENTICATION_REQ = "020",

    /**
     * Command used for communicating the authentication response.
     */
    AUTHENTICATION_RES = "021",

    /**
     * Command used for communicating the bike arrival request.
     */
    BIKE_ARRIVAL_REQ = "030",

    /**
     * Command used for communicating the bike arrival response.
     */
    BIKE_ARRIVAL_RES = "031",

    /**
     * Command used for communicating the bike checkout request.
     */
    BIKE_REMOVAL_REQ = "040",

    /**
     * Command used for communicating the bike checkout response.
     */
    BIKE_REMOVAL_RES = "041",

    /**
     * Command used for communicating the the bike checkout result.
     */
    BIKE_CHECKOUT_REQ = "050",

    /**
     * Command used for communicating the bike checkout result response.
     */
    BIKE_CHECKOUT_RES = "051",

    /**
     * Command used for communicating the status update request.
     */
    STATUS_UPDATE_REQ = "060",

    /**
     * Command used for communicating the status update response.
     */
    STATUS_UPDATE_RES = "061",

    /**
     * Command used for communicating the configuration request.
     */
    CONFIGURATION_REQ = "010",

    /**
     * Command used for communicating the configuration response.
     */
    CONFIGURATION_RES = "011",

    /**
     * Command used for communicating the keep alive request.
     */
    KEEPALIVE_REQ = "080",

    /**
     * Command used for communicating the keep alive response.
     */
    KEEPALIVE_RES = "081",

    /**
     * Command used for communicating the alert request.
     */
    ALERT_REQ = "200",

    /**
     * Command used for communicating the alert response.
     */
    ALERT_RES = "201",

    /**
     * Command used for communicating the critical errors.
     */
    CRITICAL_ERROR = "990",

    /**
     * Command used for communicating the offline database sync request.
     */
    DBSYNC_REQ = "090",

    /**
     * Command used for communicating the offline database sync response.
     */
    DBSYNC_RES = "091",

    /**
     * Command used for communicating the daylight request.
     */
    DAYLIGHTSAVING_REQ = "070",

    /**
     * Command used for communicating the daylight savings response.
     */
    DAYLIGHTSAVING_RES = "071",
}

/**
 * The codes for station commands arguments
 */
export enum StationArgCode {
    /**
     * Parameter for Station ID.
     */
    STATION_ID = "510",

    /**
     * Parameter for City ID.
     */
    CITY_ID = "511",

    /**
     * Parameter for Bike check-in confirmation.
     */
    CHECKIN_CONFIRMATION = "520",

    /**
     * Not in use.
     */
    CHAP_HASH_STRING = "530",

    /**
     * Parameter for Bike RFID.
     */
    BIKE_ID = "550",

    /**
     * Parameter for Slot ID.
     */
    SLOT_ID = "560",

    /**
     * Parameter for card type.
     */
    CARD_TYPE = "570",

    /**
     * Parameter for card RFID.
     */
    CARD_DATA = "580",

    /**
     * Parameter for IoT checkout request ID.
     */
    BIKE_CHECKOUT_REQUEST_ID = "588",

    /**
     * Parameter for action ID.
     */
    ACTION_ID = "590",

    /**
     * Parameter for bike checkout result (Y/N).
     */
    BIKE_REMOVED_RESULT = "600",

    /**
     * Parameter for slot/bikeRFID list(Used in alert).
     */
    SLOT_BIKERFID_LIST = "620",

    /**
     * Parameter for Alert ID.
     */
    ALERT_ID = "700",

    /**
     * Parameter for Alert description.
     */
    ALERT_DESCRIPTION = "710",

    /**
     * Parameter for alert status(Y/N).
     */
    ALERT_STATUS = "390",

    /**
     * Parameter for slot/Bike RFID list (Used for status message).
     */
    BIKE_SLOT_LIST = "720",

    /**
     * Parameter for penalty points.
     */
    PENALTY_POINTS = "730",

    /**
     * Parameter for maximum penalty points.
     */
    MAX_PENALTY_POINTS = "1018",

    /**
     * Parameter for bike count.
     */
    BIKE_COUNT = "740",

    /**
     * Parameter for date timestamp.
     */
    TIMESTAMP = "750",

    /**
     * Parameter for broken bike list.
     */
    BROKEN_BIKE_LIST = "760",

    /**
     * Parameter for repair bikes list.
     */
    REPAIR_BIKES = "771",

    /**
     * Not in use.
     */
    STATION_VOLTAGE = "780",

    /**
     * Not in use.
     */
    ENCRYPT = "790",

    /**
     * Parameter for server UTC time.
     */
    SERVER_UTC_TIME = "800",

    /**
     * Not in use.
     */
    FTP_SERVER_URL = "810",

    /**
     * Not in use.
     */
    FTP_USERNAME = "820",

    /**
     * Not in use.
     */
    FTP_PASSWORD = "830",

    /**
     * Parameter for status update flag.
     */
    STATUSUPDATE_FLAG = "840",

    /**
     * Parameter for action date time stamp.
     */
    STATION_ACTION_TIMESTAMP = "860",

    /**
     * Not in use.
     */
    STATION_ONLINE_STATUS = "870",

    /**
     * Not in use.
     */
    SUPERVISOR_CARDS = "880",

    /**
     * Parameter for Slot count (Used in status update).
     */
    NUMBER_OF_SLOTS = "890",

    /**
     * Not in use.
     */
    CARD_NUMBER = "900",

    /**
     * Parameter for total/active lock count.
     */
    ACTIVE_LOCK_COUNT = "910",

    /**
     * Parameter for configuration update request.
     */
    CONFIG_UPDATE_REQUIRED = "920",

    /**
     * Parameter for keep-alive period.
     */
    KEEP_ALIVE_PERIOD = "930",

    /**
     * Not in use.
     */
    PENALTY_POSTRINGS_CHANGED = "940",

    /**
     * Parameter for error.
     */
    ERROR_ID = "990",

    /**
     * Not in use.
     */
    ERROR_DESC = "1007",

    /**
     * Not in use.
     */
    MODEM_SIGNAL_STRENGTH = "1000",

    /**
     * Not in use.
     */
    BITS_PER_SECOND = "1001",

    /**
     * Not in use.
     */
    CRC = "1002",

    /**
     * Not in use.
     */
    RAS_DURATION = "1003",

    /**
     * Not in use.
     */
    RAS_TIMEOUTS = "1004",

    /**
     * Not in use.
     */
    CONFIGURATION_CHANGE = "1005",

    /**
     * Parameter for batch id.
     */
    BATCHID = "995",

    /**
     * Parameter for bitmap upgrade.
     */
    BITMAP_CHANGE = "850",

    /**
     * Parameter to indicate database sync command(offline transactions).
     */
    DBSYNC = "1012",

    /**
     * Parameter for log sync.
     */
    LOGSYNC = "705",

    /**
     * Parameter for log file upload URL.
     */
    LOGSYNCURL = "706",

    /**
     * Parameter for reboot station.
     */
    REBOOT_STATION = "1013",

    /**
     * Parameter for station software upgrade.
     */
    STATION_UPGRADE = "1014",

    /**
     * Not in use.
     */
    STATION_DISCONNECT_RECONNECT = "1015",

    /**
     * Parameter for reboot slot.
     */
    REBOOT_SLOT = "1016",

    /**
     * Parameter for subscriber/user name.
     */
    SUBSCRIBER_NAME = "1017",

    /**
     * Not in use.
     */
    USER_ERROR_REASON = "1019",

    /**
     * Parameter for user cool-off.
     */
    USER_COOLOFF = "1020",

    /**
     * Parameter for user free time.
     */
    USER_FREETIME = "1023",

    /**
     * Parameter for user recent ride charge.
     */
    RECENT_RIDE_CHARGE = "1021",

    /**
     * Parameter for near by station information.
     */
    NEARBY_STATION_INFO = "1022",

    /**
     * Parameter for station authentication key.
     */
    STATION_AUTH_KEY = "1024",

    /**
     * Parameter for keypad pin.
     */
    KEYPAD_USER_PIN = "1025",

    /**
     * Not in use.
     */
    COMMANDLIST = "1009",

    /**
     * Parameter for booked bike communication.
     */
    BOOKED_BIKE = "495",

    /**
     * Parameter for transaction ID.
     */
    TRANSACTION_ID = "707",

    // DAYLIGHT

    /**
     * Parameter for daylight saving (year) request
     */
    DAYLIGHT_YEAR = "785",

    /**
     * Parameter for daylight saving start time.
     */
    DAYLIGHT_START = "755",

    /**
     * Parameter for daylight saving end time.
     */
    DAYLIGHTEND = "765",

    /**
     * Parameter for daylight saving offset.
     */
    DAYLIGHT_OFFSET = "775",

    /**
     * Parameter for user language.
     */
    USER_LANGUAGE = "496",

    /**
     * Parameter for user preferred bike type(M/E).
     */
    PREFERRED_BIKETYPE = "497",

    /**
     * Parameter for OS Version.
     */
    OSVERSION = "499",

    /**
     * Parameter for CPU Model.
     */
    CPUMODEL = "498",

    /**
     * Parameter for ICCID Number.
     */
    ICCIDNUMBER = "395",

    /**
     * Parameter for event calendar update.
     */
    DB_EVENT_CALENDAR = "925",

    /**
     * Parameter for configuration update.
     */
    DB_CONFIGURATION = "935",

    /**
     * Parameter for slot update.
     */
    DB_SLOT = "955",

    /**
     * Parameter for supervisor update.
     */
    DB_SUPERVISOR = "965",

    /**
     * Parameter for transaction update.
     */
    DB_TRANSACTIONS = "975",

    /**
     * Parameter for UI Language update.
     */
    DB_UILANGUAGE = "985",

    /**
     * Parameter for database sync status.
     */
    DB_SYNC_STATUS = "1010",

    /**
     * Parameter for user update.
     */
    DB_USER = "905",

    /**
     * Parameter for city calendar update.
     */
    DB_CITYCALENDAR = "715",

    /**
     * Parameter for UI Message update.
     */
    DB_UIMESSAGE = "610",

    /**
     * Parameter for UI Font update.
     */
    DB_UIFONT = "986",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYEVENT = "630",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYTIME = "465",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONEMPTY = "635",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONFULL = "640",

    /**
     * Parameter for KIOSK Message.
     */
    UI_OFFLINESTATIONCLOSED_BYEVENT = "650",

    /**
     * Parameter for KIOSK Message.
     */
    UI_OFFLINESTATIONCLOSED_BYTIME = "470",

    /**
     * Parameter for KIOSK Message.
     */
    UI_OFFLINESTATIONEMPTY = "655",

    /**
     * Parameter for KIOSK Message.
     */
    UI_OFFLINESTATIONFULL = "660",

    /**
     * Parameter for KIOSK Message.
     */
    UI_WELCOME = "400",

    /**
     * Parameter for KIOSK Message.
     */
    UI_NONOPERATIONAL = "680",

    /**
     * Parameter for KIOSK Message.
     */
    UI_STATIONOFFLINENOTSUPPORTED = "430",

    /**
     * Parameter for KIOSK Message.
     */
    UI_NONOPERATIONAL_LOCKCONTROLLER = "300",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYEVENT_WITHOUTKEYPAD = "310",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYTIME_WITHOUTKEYPAD = "315",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONEMPTY_WITHOUTKEYPAD = "320",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONFULL_WITHOUTKEYPAD = "325",

    /**
     * Parameter for KIOSK Message.
     */
    UI_WELCOME_WITHOUTKEYPAD = "350",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYEVENT_RFIDREADERNONFUNCTIONAL = "251",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYTIME_RFIDREADERNONFUNCTIONAL = "253",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONEMPTY_RFIDREADERNONFUNCTIONAL = "255",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONFULL_RFIDREADERNONFUNCTIONAL = "257",

    /**
     * Parameter for KIOSK Message.
     */
    UI_WELCOME_RFIDREADERNONFUNCTIONAL = "259",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYEVENT_KEYPADNONFUNCTIONAL = "250",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONCLOSED_BYTIME_KEYPADNONFUNCTIONAL = "252",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONEMPTY_KEYPADNONFUNCTIONAL = "254",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINESTATIONFULL_KEYPADNONFUNCTIONAL = "256",

    /**
     * Parameter for KIOSK Message.
     */
    UI_WELCOME_KEYPADNONFUNCTIONAL = "258",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINEUSERBIKEREMOVAL_WITHPENALITY = "615",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINEUSERBIKEREMOVAL_WITHOUTPENALITY = "460",

    /**
     * Parameter for KIOSK Message.
     */
    UI_OFFLINEUSERBIKEREMOVAL = "645",

    /**
     * Parameter for KIOSK Message.
     */
    UI_STATIONPROCESSING = "380",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINEUSERCOOLOFF_WITH_RIDECHARGE = "665",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINEUSERCOOLOFF_WITHOUT_RIDECHARGE = "480",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINENEARBYSTATIONSEMPTY = "670",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINENEARBYSTATIONS_FULL_WITH_FREEMINUTES = "675",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ONLINENEARBYSTATIONS_FULL_WITHOUT_FREEMINUTES = "485",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SUPERVISOR = "410",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SUPERVISOREXIT = "490",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SELECTBIKETYPE_USINGKEYPAD = "440",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SELECTBIKETYPE_USINGCARD = "330",

    /**
     * Parameter for KIOSK Message.
     */
    UI_CHECKIN_CONFIRMATION = "260",

    /**
     * Parameter for Help screen KIOSK message.
     */
    UI_HELPSCREEN = "261",

    /**
     * Parameter for AR success message.
     */
    UI_BIKERELEASE_SUCCESS = "263",

    /**
     * Parameter for AR failed message.
     */
    UI_BIKERELEASE_FAILED = "264",

    /**
     * Parameter for KIOSK Message.
     */
    UI_URL = "685",

    /**
     * Parameter for KIOSK Message.
     */
    UI_CUSTSUPPORT = "690",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SUPPORTEDLANGUAGES = "450",

    /**
     * Parameter for KIOSK Message.
     */
    UI_GENERICUSERERROR = "625",

    /**
     * Parameter for KIOSK Message.
     */
    UI_SUPERVISORERROR = "420",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CUSTOMERALREADYHASBIKE = "18",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CARDORPINNOTRECOGNIZED = "20",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_SUBSCRIPTIONNOTACTIVE = "37",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CREDITCARDEXPIRED = "31",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CARDBLACKLISTUSAGE = "30",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CARDBLACKLISTPAYMENT = "41",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_CARDBLACKLISTTEMPORARY = "42",

    /**
     * Parameter for KIOSK Message.
     */
    UI_ERROR_SUBSCRIPTIONEXPIRED = "38",

    /**
     * Parameter for Bike already exists message with help instructions.
     */
    UI_ERROR_BIKEALREADYEXISTS_WITHELP = "262",

    /**
     * Parameter for keypad user-id message.
     */
    KEYPAD_USERID = "355",

    /**
     * Parameter for keypad bike type message.
     */
    KEYPAD_BIKETYPE = "365",

    /**
     * Parameter for keypad language input message.
     */
    KEYPAD_LANGUAGE = "375",

    /**
     * Parameter for keypad user-pin message.
     */
    KEYPAD_USERPIN = "360",

    /**
     * Parameter for keypad processing message.
     */
    KEYPAD_PROCESSING = "370",

    /**
     * Parameter for keypad bike-checkin confirmation message.
     */
    KEYPAD_BIKECHECKINCONFIRMATION = "356",

    /**
     * Parameter for keypad slot number message.
     */
    KEYPAD_SLOTNUMBER = "357",

    /**
     * Parameter for keypad bike number message.
     */
    KEYPAD_BIKENUMBER = "358",

    /**
     * Parameter for keypad user id and card tap message.
     */
    KEYPAD_USERIDORCARDTAP = "359",

    /**
     * Parameter for keypad error message.
     */
    KEYPAD_ERROR = "361",
}

/**
 * The codes for the respective station generated alerts
 */
export enum StationAlertCode {
    /**
     * Alert code for bike ID unavailable alert.
     */
    BIKE_ID_UNAVAILABLE = "72",

    /**
     * Alert code for lock initialization error alert.
     */
    LOCK_INITIALIZATION_ERROR = "73",

    /**
     * Alert code for slot LED failure
     */
    SLOT_LED_FAILURE = "75",

    /**
     * Alert code for locked slot emptied scenario.
     */
    LOCKED_SLOT_EMPTIED = "81",

    /**
     * Alert code when bike id is changed without any actions performed on it.
     */
    BIKE_ID_CHANGED = "82",

    /**
     * Alert code when lock is not opening as per the request.
     */
    LOCK_NOT_OPENING = "86",

    /**
     * Alert code when lock is not closing as per the request.
     */
    LOCK_NOT_CLOSING = "87",

    /**
     * Alert code when slot fails to respond properly (broken slot alert)
     */
    SLOT_ERROR = "70",

    /**
     * Alert code that need to be used when booked bike is not removed by the subscriber.
     */
    BOOKED_BIKE_NOT_REMOVED = "500",

    /**
     * Alert code that need to be used when locks initialize non sequentially.
     */
    LOCK_NONSEQ_INIT_COMPLETED = "17",

    /**
     * Alert code that need to be used when locks initlialize on demand by supervisor (sequentially).
     */
    LOCK_SUP_INIT_COMPLETED = "18",

    // SUPERVISOR

    /**
     * Alert code to indicate that supervisor mode is activated at station.
     */
    SUPERVISOR_MODE_ACTIVATED = "100",

    /**
     *  Alert code to indicate that station has exit from supervisor mode.
     */
    SUPERVISOR_MODE_DEACTIVATED = "109",

    // SYSTEM

    /**
     *  Alert code to indicate that station is started and authenticated with BKO.
     */
    STATION_STARTUP = "110",

    /**
     *  Alert code that indicates that there is some problem during the startup of the application.
     */
    STATION_STARTUP_ERROR = "150",

    /**
     *  Alert code to indicate that there is some system problem during the run time of application.
     */
    STATION_RUNTIME_ERROR = "94",

    /**
     * Alert code indicating that there is a time sync failure.
     */
    TIME_SYNC_FAILURE = "12",

    // CARD READER

    /**
     *  Alert code to be used whenever unassigned card is tapped at station.
     */
    UNASSIGNED_CARD = "120",

    // DOWNLOADS AND UPDATES

    /**
     *  Alert code that indicates the status of cab file download
     */
    DOWNLOAD_CAB_FILE = "130",

    /**
     *  Alert code that indicates the status of downloading the image files.
     */
    DOWNLOAD_IMAGE_FILE = "93",

    /**
     * Alert code that indicates that station software is upgraded successfully.
     */
    SOFTWARE_UPDATED = "50",

    /**
     * Alert code to indicate that loading KIOSK images failed.
     */
    LOAD_KIOSK_IMAGE_FAILURE = "174",

    // ADMIN-SYS DIAGNOSTICS

    /**
     *  Alert code that indicates the station is opened according to the calendar.
     */
    OPEN_MORNING = "160",

    /**
     *  Alert code that indicates that station is closed because of calendar.
     */
    CLOSE_NIGHT = "170",

    /**
     *  Alert code indicating that disk space is below defined threshold value.
     */
    LOW_AVAILABLE_DISK_SPACE = "95",

    /**
     *  Alert code indicating that ICCID number of SIM is changed at the station.
     */
    MOBILE_NUMBER_CHANGE = "60",

    // LOG FILE

    /**
     *  Alert code indicating that log files are uploaded successfully to BKO.
     */
    LOG_SYNC_UPDATED = "172",

    /**
     *  Alert code used to indicate that log files are failed to upload to BKO.
     */
    LOG_SYNC_UPDATE_ERROR = "173",

    // DB LOADS

    /**
     *  Alert code that indicates the status of processing of calendar updates.
     */
    DB_CALENDAR_LOAD = "99",

    /**
     * Alert code that indicates the status of processing of configuration updates.
     */
    DB_CONFIG_LOAD = "101",

    /**
     * Alert code that indicates the status of processing supervisor updates.
     */
    DB_SUPERVISOR_LOAD = "102",

    /**
     * Alert code that indicates the status of processing user updates.
     */
    DB_USER_LOAD = "103",

    /**
     * Alert code that indicates the status of processing KIOSK message updates.
     */
    DB_UIMESSAGES_LOAD = "104",

    /**
     * Alert code that indicates the status of processing language updates.
     */
    DB_LANGUAGES_LOAD = "105",

    /**
     * Alert code that indicates the status of processing KIOSK font updates.
     */
    DB_UIFONTS_LOAD = "106",

    /**
     * Alert code that indicates the status of processing slot updates.
     */
    DB_SLOTS_LOAD = "107",

    // STATION H/W STATUS

    /**
     * Alert code that indicates the station hardware initialization (key peripherals) is success.
     */
    STATION_HARDWARE_INIT_SUCCESS = "501",

    /**
     * Alert code that indicates the station h/w initialization is failed.
     */
    STATION_HARDWARE_INIT_FAILURE = "502",

    /**
     * Alert code used to indicate that KeyPad is non functional.
     */
    KEYPAD_NONFUNCTIONAL = "14",

    /**
     * Alert code used to indicate that card reader is not functional.
     */
    CARDREADER_NONFUNCTIONAL = "13",

    /**
     * Alert code that indicates that modem is non functional.
     */
    MODEM_NONFUNCTIONAL = "15",

    // MISC

    /**
     * Alert code that indicates the status of loading the preloaded updates in to the station database.
     */
    PRELOADED_UPDATES_STATUS = "16",
}

export enum StationErrorCode {
    NO_ERROR = "0",
    SQL_ERROR = "1",
    UNABLE_TO_GET_DB_CONNECTION = "2",
    NO_USER_FOUND_FOR_BIKEID = "3",
    INVALID_STATION_ID = "4",
    BIKE_STAND_EMPTY = "5",
    NO_SHARED_KEY_ASSIGNED = "6",
    INVALID_ACTION_ID = "7",
    NOT_AUTHENTICATED = "8",
    INVALID_COMMAND = "9",
    SERVER_EXCEPTION = "10",
    INVALID_ACTION_OR_SLOT_ID = "11",
    CLIENT_MESSAGE_OUT_OF_SYNC = "12",
    INVALID_MESSAGE_NUMBER_FORMAT = "13",
    CLIENT_MESSAGE_OUT_OF_RANGE = "14",
    AUTHENTICATION_FAILED = "15",
    SERVER_SHUTDOWN = "16",
    INVALID_BIKEID = "17",
    BIKE_ALREADY_EXISTS = "18",
    USER_BLACKLISTED = "19",
    CARD_DOES_NOT_EXIST = "20",
    MISSING_PARAMETER = "21",
    INVALID_CARD_TYPE = "22",
    USER_ALREADY_HAS_BIKE = "23",
    RETURNED_BIKE_BROKEN = "24",
    UNKNOWN_CARD_TYPE = "25",
    CARD_EXPIRED = "29",
    CARD_BLACKLISTED = "30",
    CREDIT_CARD_EXPIRED = "31",
    NO_INVOICE_RECORD_FOR_CARD = "32",
    COOLOFF_PERIOD = "33",
    PAYMENT_MEAN_NOT_PROVIDED = "34",
    SUBSCRIPTION_NOT_ACTIVE = "37",
    SUBSCRIPTION_EXPIRED = "38",
    PAYMENT_BLACKLISTED = "41",
    TEMPORARY_BLACKLISTED = "42",
    UNSPECIFIED_ERROR = "999",
}

/**
 * Maps a station command code to the command name
 */
export const stationCommands = new Map<string, string>([
    [StationCommandCode.AUTHENTICATION_REQ, "authentication_req"],
    [StationCommandCode.AUTHENTICATION_RES, "authentication_res"],
    [StationCommandCode.BIKE_ARRIVAL_REQ, "bike_arrival_req"],
    [StationCommandCode.BIKE_ARRIVAL_RES, "bike_arrival_res"],
    [StationCommandCode.BIKE_REMOVAL_REQ, "bike_removal_req"],
    [StationCommandCode.BIKE_REMOVAL_RES, "bike_removal_res"],
    [StationCommandCode.BIKE_CHECKOUT_REQ, "bike_checkout_req"],
    [StationCommandCode.BIKE_CHECKOUT_RES, "bike_checkout_res"],
    [StationCommandCode.STATUS_UPDATE_REQ, "status_update_req"],
    [StationCommandCode.STATUS_UPDATE_RES, "status_update_res"],
    [StationCommandCode.CONFIGURATION_REQ, "configuration_req"],
    [StationCommandCode.CONFIGURATION_RES, "configuration_res"],
    [StationCommandCode.KEEPALIVE_REQ, "keepalive_req"],
    [StationCommandCode.KEEPALIVE_RES, "keepalive_res"],
    [StationCommandCode.ALERT_REQ, "alert_req"],
    [StationCommandCode.ALERT_RES, "alert_res"],
    [StationCommandCode.CRITICAL_ERROR, "critical_error"],
    [StationCommandCode.DBSYNC_REQ, "dbsync_req"],
    [StationCommandCode.DBSYNC_RES, "dbsync_res"],
    [StationCommandCode.DAYLIGHTSAVING_REQ, "daylightsaving_req"],
    [StationCommandCode.DAYLIGHTSAVING_RES, "daylightsaving_res"],
]);

/**
 * Returns the specified command name.
 * Throws if the name is not found.
 * @param id The command id
 */
export function commandNameById(id: string): string {
    const name = stationCommands.get(id);
    if (isNil(name)) {
        throw new Error("Command not found:".concat(id));
    }
    return name;
}

/**
 * Maps a station argument code to the argument name
 */
export const stationArguments = new Map<string, string>([
    [StationArgCode.STATION_ID, "station_id"],
    [StationArgCode.CITY_ID, "city_id"],
    [StationArgCode.CHECKIN_CONFIRMATION, "checkin_confirmation"],
    [StationArgCode.CHAP_HASH_STRING, "chap_hash_string"],
    [StationArgCode.BIKE_ID, "bike_id"],
    [StationArgCode.SLOT_ID, "slot_id"],
    [StationArgCode.CARD_TYPE, "card_type"],
    [StationArgCode.CARD_DATA, "card_data"],
    [StationArgCode.BIKE_CHECKOUT_REQUEST_ID, "bike_checkout_request_id"],
    [StationArgCode.ACTION_ID, "action_id"],
    [StationArgCode.BIKE_REMOVED_RESULT, "bike_removed_result"],
    [StationArgCode.SLOT_BIKERFID_LIST, "slot_bike_rfid_list"],
    [StationArgCode.ALERT_ID, "alert_id"],
    [StationArgCode.ALERT_DESCRIPTION, "alert_description"],
    [StationArgCode.ALERT_STATUS, "alert_status"],
    [StationArgCode.BIKE_SLOT_LIST, "bike_slot_list"],
    [StationArgCode.PENALTY_POINTS, "penalty_points"],
    [StationArgCode.MAX_PENALTY_POINTS, "max_penalty_points"],
    [StationArgCode.BIKE_COUNT, "bike_count"],
    [StationArgCode.TIMESTAMP, "ts"],
    [StationArgCode.BROKEN_BIKE_LIST, "broken_bike_list"],
    [StationArgCode.REPAIR_BIKES, "repair_bikes"],
    [StationArgCode.STATION_VOLTAGE, "station_voltage"],
    [StationArgCode.ENCRYPT, "encrypt"],
    [StationArgCode.SERVER_UTC_TIME, "server_utc_time"],
    [StationArgCode.FTP_SERVER_URL, "ftp_server_url"],
    [StationArgCode.FTP_USERNAME, "ftp_username"],
    [StationArgCode.FTP_PASSWORD, "ftp_password"],
    [StationArgCode.STATUSUPDATE_FLAG, "status_update_flag"],
    [StationArgCode.STATION_ACTION_TIMESTAMP, "station_action_timestamp"],
    [StationArgCode.STATION_ONLINE_STATUS, "station_online_status"],
    [StationArgCode.SUPERVISOR_CARDS, "supervisor_cards"],
    [StationArgCode.NUMBER_OF_SLOTS, "number_of_slots"],
    [StationArgCode.CARD_NUMBER, "card_number"],
    [StationArgCode.ACTIVE_LOCK_COUNT, "active_lock_count"],
    [StationArgCode.CONFIG_UPDATE_REQUIRED, "config_update_required"],
    [StationArgCode.KEEP_ALIVE_PERIOD, "keep_alive_period"],
    [StationArgCode.PENALTY_POSTRINGS_CHANGED, "penalty_postrings_changed"],
    [StationArgCode.ERROR_ID, "error_id"],
    [StationArgCode.ERROR_DESC, "error_desc"],
    [StationArgCode.MODEM_SIGNAL_STRENGTH, "modem_signal_strength"],
    [StationArgCode.BITS_PER_SECOND, "bits_per_second"],
    [StationArgCode.CRC, "crc"],
    [StationArgCode.RAS_DURATION, "ras_duration"],
    [StationArgCode.RAS_TIMEOUTS, "ras_timeouts"],
    [StationArgCode.CONFIGURATION_CHANGE, "configuration_change"],
    [StationArgCode.BATCHID, "batch_id"],
    [StationArgCode.BITMAP_CHANGE, "bitmap_change"],
    [StationArgCode.DBSYNC, "dbsync"],
    [StationArgCode.LOGSYNC, "logsync"],
    [StationArgCode.LOGSYNCURL, "logsync_url"],
    [StationArgCode.REBOOT_STATION, "reboot_station"],
    [StationArgCode.STATION_UPGRADE, "station_upgrade"],
    [
        StationArgCode.STATION_DISCONNECT_RECONNECT,
        "station_disconnect_reconnect",
    ],
    [StationArgCode.REBOOT_SLOT, "reboot_slot"],
    [StationArgCode.SUBSCRIBER_NAME, "subscriber_name"],
    [StationArgCode.USER_ERROR_REASON, "user_error_reason"],
    [StationArgCode.USER_COOLOFF, "user_cooloff"],
    [StationArgCode.USER_FREETIME, "user_freetime"],
    [StationArgCode.RECENT_RIDE_CHARGE, "recent_ride_charge"],
    [StationArgCode.NEARBY_STATION_INFO, "nearby_station_info"],
    [StationArgCode.STATION_AUTH_KEY, "station_auth_key"],
    [StationArgCode.KEYPAD_USER_PIN, "keypad_user_pin"],
    [StationArgCode.COMMANDLIST, "command_list"],
    [StationArgCode.BOOKED_BIKE, "booked_bike"],
    [StationArgCode.TRANSACTION_ID, "tr"],
    [StationArgCode.DAYLIGHT_YEAR, "daylight_year"],
    [StationArgCode.DAYLIGHT_START, "daylight_start"],
    [StationArgCode.DAYLIGHTEND, "daylight_end"],
    [StationArgCode.DAYLIGHT_OFFSET, "daylight_offset"],
    [StationArgCode.USER_LANGUAGE, "user_language"],
    [StationArgCode.PREFERRED_BIKETYPE, "preferred_bike_type"],
    [StationArgCode.OSVERSION, "os_version"],
    [StationArgCode.CPUMODEL, "cpu_model"],
    [StationArgCode.ICCIDNUMBER, "iccid_number"],
    [StationArgCode.DB_EVENT_CALENDAR, "db_event_calendar"],
    [StationArgCode.DB_CONFIGURATION, "db_configuration"],
    [StationArgCode.DB_SLOT, "db_slot"],
    [StationArgCode.DB_SUPERVISOR, "db_supervisor"],
    [StationArgCode.DB_TRANSACTIONS, "db_transactions"],
    [StationArgCode.DB_UILANGUAGE, "db_ui_language"],
    [StationArgCode.DB_SYNC_STATUS, "db_sync_status"],
    [StationArgCode.DB_USER, "db_user"],
    [StationArgCode.DB_CITYCALENDAR, "db_city_calendar"],
    [StationArgCode.DB_UIMESSAGE, "db_ui_message"],
    [StationArgCode.DB_UIFONT, "db_ui_font"],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYEVENT,
        "ui_online_station_closed_byevent",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYTIME,
        "ui_online_station_closed_bytime",
    ],
    [StationArgCode.UI_ONLINESTATIONEMPTY, "ui_online_station_empty"],
    [StationArgCode.UI_ONLINESTATIONFULL, "ui_online_station_full"],
    [
        StationArgCode.UI_OFFLINESTATIONCLOSED_BYEVENT,
        "ui_offline_station_closed_byevent",
    ],
    [
        StationArgCode.UI_OFFLINESTATIONCLOSED_BYTIME,
        "ui_offline_station_closed_bytime",
    ],
    [StationArgCode.UI_OFFLINESTATIONEMPTY, "ui_offline_station_empty"],
    [StationArgCode.UI_OFFLINESTATIONFULL, "ui_offline_station_full"],
    [StationArgCode.UI_WELCOME, "ui_welcome"],
    [StationArgCode.UI_NONOPERATIONAL, "ui_nonoperational"],
    [
        StationArgCode.UI_STATIONOFFLINENOTSUPPORTED,
        "ui_station_offline_not_supported",
    ],
    [
        StationArgCode.UI_NONOPERATIONAL_LOCKCONTROLLER,
        "ui_non_operational_lock_controller",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYEVENT_WITHOUTKEYPAD,
        "ui_online_station_closed_byevent_without_keypad",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYTIME_WITHOUTKEYPAD,
        "ui_online_station_closed_bytime_without_keypad",
    ],
    [
        StationArgCode.UI_ONLINESTATIONEMPTY_WITHOUTKEYPAD,
        "ui_online_station_empty_without_keypad",
    ],
    [
        StationArgCode.UI_ONLINESTATIONFULL_WITHOUTKEYPAD,
        "ui_online_station_full_without_keypad",
    ],
    [StationArgCode.UI_WELCOME_WITHOUTKEYPAD, "ui_welcome_without_keypad"],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYEVENT_RFIDREADERNONFUNCTIONAL,
        "ui_online_station_closed_byevent_rfid_reader_nonfunctional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYTIME_RFIDREADERNONFUNCTIONAL,
        "ui_online_station_closed_bytime_rfid_reader_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONEMPTY_RFIDREADERNONFUNCTIONAL,
        "ui_online_station_empty_rfid_reader_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONFULL_RFIDREADERNONFUNCTIONAL,
        "ui_online_station_full_rfid_reader_non_functional",
    ],
    [
        StationArgCode.UI_WELCOME_RFIDREADERNONFUNCTIONAL,
        "ui_welcome_rfid_reader_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYEVENT_KEYPADNONFUNCTIONAL,
        "ui_online_station_closed_byevent_keypad_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONCLOSED_BYTIME_KEYPADNONFUNCTIONAL,
        "ui_online_station_closed_bytime_keypad_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONEMPTY_KEYPADNONFUNCTIONAL,
        "ui_online_station_empty_keypad_non_functional",
    ],
    [
        StationArgCode.UI_ONLINESTATIONFULL_KEYPADNONFUNCTIONAL,
        "ui_online_station_full_keypad_non_functional",
    ],
    [
        StationArgCode.UI_WELCOME_KEYPADNONFUNCTIONAL,
        "ui_welcome_keypad_non_functional",
    ],
    [
        StationArgCode.UI_ONLINEUSERBIKEREMOVAL_WITHPENALITY,
        "ui_online_user_bikeremoval_with_penality",
    ],
    [
        StationArgCode.UI_ONLINEUSERBIKEREMOVAL_WITHOUTPENALITY,
        "ui_online_user_bikeremoval_without_penality",
    ],
    [StationArgCode.UI_OFFLINEUSERBIKEREMOVAL, "ui_offline_user_bike_removal"],
    [StationArgCode.UI_STATIONPROCESSING, "ui_station_processing"],
    [
        StationArgCode.UI_ONLINEUSERCOOLOFF_WITH_RIDECHARGE,
        "ui_online_user_cooloff_with_ridecharge",
    ],
    [
        StationArgCode.UI_ONLINEUSERCOOLOFF_WITHOUT_RIDECHARGE,
        "ui_online_user_cooloff_without_ridecharge",
    ],
    [
        StationArgCode.UI_ONLINENEARBYSTATIONSEMPTY,
        "ui_onlinene_ar_by_stations_empty",
    ],
    [
        StationArgCode.UI_ONLINENEARBYSTATIONS_FULL_WITH_FREEMINUTES,
        "ui_onlinenearbystations_full_with_free_minutes",
    ],
    [
        StationArgCode.UI_ONLINENEARBYSTATIONS_FULL_WITHOUT_FREEMINUTES,
        "ui_online_nearby_stations_full_without_free_minutes",
    ],
    [StationArgCode.UI_SUPERVISOR, "ui_supervisor"],
    [StationArgCode.UI_SUPERVISOREXIT, "ui_supervisor_exit"],
    [
        StationArgCode.UI_SELECTBIKETYPE_USINGKEYPAD,
        "ui_select_bike_type_using_keypad",
    ],
    [
        StationArgCode.UI_SELECTBIKETYPE_USINGCARD,
        "ui_select_bike_type_using_card",
    ],
    [StationArgCode.UI_CHECKIN_CONFIRMATION, "ui_checkin_confirmation"],
    [StationArgCode.UI_HELPSCREEN, "ui_help_screen"],
    [StationArgCode.UI_BIKERELEASE_SUCCESS, "ui_bike_release_success"],
    [StationArgCode.UI_BIKERELEASE_FAILED, "ui_bike_release_failed"],
    [StationArgCode.UI_URL, "ui_url"],
    [StationArgCode.UI_CUSTSUPPORT, "ui_cust_support"],
    [StationArgCode.UI_SUPPORTEDLANGUAGES, "ui_supported_languages"],
    [StationArgCode.UI_GENERICUSERERROR, "ui_generic_user_error"],
    [StationArgCode.UI_SUPERVISORERROR, "ui_supervisor_error"],
    [
        StationArgCode.UI_ERROR_CUSTOMERALREADYHASBIKE,
        "ui_error_customer_already_has_bike",
    ],
    [
        StationArgCode.UI_ERROR_CARDORPINNOTRECOGNIZED,
        "ui_error_card_or_pin_not_recognized",
    ],
    [
        StationArgCode.UI_ERROR_SUBSCRIPTIONNOTACTIVE,
        "ui_error_subscription_not_active",
    ],
    [StationArgCode.UI_ERROR_CREDITCARDEXPIRED, "ui_error_creditcard_expired"],
    [
        StationArgCode.UI_ERROR_CARDBLACKLISTUSAGE,
        "ui_error_card_blacklist_usage",
    ],
    [
        StationArgCode.UI_ERROR_CARDBLACKLISTPAYMENT,
        "ui_error_card_blacklist_payment",
    ],
    [
        StationArgCode.UI_ERROR_CARDBLACKLISTTEMPORARY,
        "ui_error_card_blacklist_temporary",
    ],
    [
        StationArgCode.UI_ERROR_SUBSCRIPTIONEXPIRED,
        "ui_error_subscription_expired",
    ],
    [
        StationArgCode.UI_ERROR_BIKEALREADYEXISTS_WITHELP,
        "ui_error_bike_already_exists_withelp",
    ],
    [StationArgCode.KEYPAD_USERID, "keypad_userid"],
    [StationArgCode.KEYPAD_BIKETYPE, "keypad_biketype"],
    [StationArgCode.KEYPAD_LANGUAGE, "keypad_language"],
    [StationArgCode.KEYPAD_USERPIN, "keypad_userpin"],
    [StationArgCode.KEYPAD_PROCESSING, "keypad_processing"],
    [
        StationArgCode.KEYPAD_BIKECHECKINCONFIRMATION,
        "keypad_bike_checkin_confirmation",
    ],
    [StationArgCode.KEYPAD_SLOTNUMBER, "keypad_slot_number"],
    [StationArgCode.KEYPAD_BIKENUMBER, "keypad_bike_number"],
    [StationArgCode.KEYPAD_USERIDORCARDTAP, "keypad_userid_or_cardtap"],
    [StationArgCode.KEYPAD_ERROR, "keypad_error"],
]);

/**
 * Returns the specified argument name.
 * Throws if the name is not found.
 * @param id The argument id
 */
export function argumentNameById(id: string): string {
    const name = stationArguments.get(id);
    if (isNil(name)) {
        throw new Error("Command not found:".concat(id));
    }
    return name;
}

/**
 * The codes for the respective station generated alerts
 */
export const stationAlertCodes = new Map<string, string>([
    [StationAlertCode.BIKE_ID_UNAVAILABLE, "bike_id_unavailable"],
    [StationAlertCode.LOCK_INITIALIZATION_ERROR, "lock_initialization_error"],
    [StationAlertCode.SLOT_LED_FAILURE, "slot_led_failure"],
    [StationAlertCode.LOCKED_SLOT_EMPTIED, "locked_slot_emptied"],
    [StationAlertCode.BIKE_ID_CHANGED, "bike_id_changed"],
    [StationAlertCode.LOCK_NOT_OPENING, "lock_not_opening"],
    [StationAlertCode.LOCK_NOT_CLOSING, "lock_not_closing"],
    [StationAlertCode.SLOT_ERROR, "slot_error"],
    [StationAlertCode.BOOKED_BIKE_NOT_REMOVED, "booked_bike_not_removed"],
    [StationAlertCode.LOCK_NONSEQ_INIT_COMPLETED, "lock_nonseq_init_completed"],
    [StationAlertCode.LOCK_SUP_INIT_COMPLETED, "lock_sup_init_completed"],
    [StationAlertCode.SUPERVISOR_MODE_ACTIVATED, "supervisor_mode_activated"],
    [
        StationAlertCode.SUPERVISOR_MODE_DEACTIVATED,
        "supervisor_mode_deactivated",
    ],
    [StationAlertCode.STATION_STARTUP, "station_startup"],
    [StationAlertCode.STATION_STARTUP_ERROR, "station_startup_error"],
    [StationAlertCode.STATION_RUNTIME_ERROR, "station_runtime_error"],
    [StationAlertCode.TIME_SYNC_FAILURE, "time_sync_failure"],
    [StationAlertCode.UNASSIGNED_CARD, "unassigned_card"],
    [StationAlertCode.DOWNLOAD_CAB_FILE, "download_cab_file"],
    [StationAlertCode.DOWNLOAD_IMAGE_FILE, "download_image_file"],
    [StationAlertCode.SOFTWARE_UPDATED, "software_updated"],
    [StationAlertCode.LOAD_KIOSK_IMAGE_FAILURE, "load_kiosk_image_failure"],
    [StationAlertCode.OPEN_MORNING, "open_morning"],
    [StationAlertCode.CLOSE_NIGHT, "close_night"],
    [StationAlertCode.LOW_AVAILABLE_DISK_SPACE, "low_available_disk_space"],
    [StationAlertCode.MOBILE_NUMBER_CHANGE, "mobile_number_change"],
    [StationAlertCode.LOG_SYNC_UPDATED, "log_sync_updated"],
    [StationAlertCode.LOG_SYNC_UPDATE_ERROR, "log_sync_update_error"],
    [StationAlertCode.DB_CALENDAR_LOAD, "db_calendar_load"],
    [StationAlertCode.DB_CONFIG_LOAD, "db_config_load"],
    [StationAlertCode.DB_SUPERVISOR_LOAD, "db_supervisor_load"],
    [StationAlertCode.DB_USER_LOAD, "db_user_load"],
    [StationAlertCode.DB_UIMESSAGES_LOAD, "db_ui_messages_load"],
    [StationAlertCode.DB_LANGUAGES_LOAD, "db_languages_load"],
    [StationAlertCode.DB_UIFONTS_LOAD, "db_ui_fonts_load"],
    [StationAlertCode.DB_SLOTS_LOAD, "db_slots_load"],
    [
        StationAlertCode.STATION_HARDWARE_INIT_SUCCESS,
        "station_hardware_init_success",
    ],
    [
        StationAlertCode.STATION_HARDWARE_INIT_FAILURE,
        "station_hardware_init_failure",
    ],
    [StationAlertCode.KEYPAD_NONFUNCTIONAL, "keypad_non_functional"],
    [StationAlertCode.CARDREADER_NONFUNCTIONAL, "card_reader_non_functional"],
    [StationAlertCode.MODEM_NONFUNCTIONAL, "modem_non_functional"],
    [StationAlertCode.PRELOADED_UPDATES_STATUS, "preloaded_updates_status"],
]);

/**
 * Maps a station error code
 */
export const stationErrorCodes = new Map<string, string>([
    [StationErrorCode.NO_ERROR, "no_error"],
    [StationErrorCode.SQL_ERROR, "sql_error"],
    [
        StationErrorCode.UNABLE_TO_GET_DB_CONNECTION,
        "unable_to_get_db_connection",
    ],
    [StationErrorCode.NO_USER_FOUND_FOR_BIKEID, "no_user_found_for_bikeid"],
    [StationErrorCode.INVALID_STATION_ID, "invalid_station_id"],
    [StationErrorCode.BIKE_STAND_EMPTY, "bike_stand_empty"],
    [StationErrorCode.NO_SHARED_KEY_ASSIGNED, "no_shared_key_assigned"],
    [StationErrorCode.INVALID_ACTION_ID, "invalid_action_id"],
    [StationErrorCode.NOT_AUTHENTICATED, "not_authenticated"],
    [StationErrorCode.INVALID_COMMAND, "invalid_command"],
    [StationErrorCode.SERVER_EXCEPTION, "server_exception"],
    [StationErrorCode.INVALID_ACTION_OR_SLOT_ID, "invalid_action_or_slot_id"],
    [StationErrorCode.CLIENT_MESSAGE_OUT_OF_SYNC, "client_message_out_of_sync"],
    [
        StationErrorCode.INVALID_MESSAGE_NUMBER_FORMAT,
        "invalid_message_number_format",
    ],
    [
        StationErrorCode.CLIENT_MESSAGE_OUT_OF_RANGE,
        "client_message_out_of_range",
    ],
    [StationErrorCode.AUTHENTICATION_FAILED, "authentication_failed"],
    [StationErrorCode.SERVER_SHUTDOWN, "server_shutdown"],
    [StationErrorCode.INVALID_BIKEID, "invalid_bikeid"],
    [StationErrorCode.BIKE_ALREADY_EXISTS, "bike_already_exists"],
    [StationErrorCode.USER_BLACKLISTED, "user_blacklisted"],
    [StationErrorCode.CARD_DOES_NOT_EXIST, "card_does_not_exist"],
    [StationErrorCode.MISSING_PARAMETER, "missing_parameter"],
    [StationErrorCode.INVALID_CARD_TYPE, "invalid_card_type"],
    [StationErrorCode.USER_ALREADY_HAS_BIKE, "user_already_has_bike"],
    [StationErrorCode.RETURNED_BIKE_BROKEN, "returned_bike_broken"],
    [StationErrorCode.UNKNOWN_CARD_TYPE, "unknown_card_type"],
    [StationErrorCode.CARD_EXPIRED, "card_expired"],
    [StationErrorCode.CARD_BLACKLISTED, "card_blacklisted"],
    [StationErrorCode.CREDIT_CARD_EXPIRED, "credit_card_expired"],
    [StationErrorCode.NO_INVOICE_RECORD_FOR_CARD, "no_invoice_record_for_card"],
    [StationErrorCode.COOLOFF_PERIOD, "cooloff_period"],
    [StationErrorCode.PAYMENT_MEAN_NOT_PROVIDED, "payment_mean_not_provided"],
    [StationErrorCode.SUBSCRIPTION_NOT_ACTIVE, "subscription_not_active"],
    [StationErrorCode.SUBSCRIPTION_EXPIRED, "subscription_expired"],
    [StationErrorCode.PAYMENT_BLACKLISTED, "payment_blacklisted"],
    [StationErrorCode.TEMPORARY_BLACKLISTED, "temporary_blacklisted"],
    [StationErrorCode.UNSPECIFIED_ERROR, "unspecified_error"],
]);

/**
 * The known argument values
 */
export const stationKnownValues = new Map<string, Map<string, string>>([
    [StationArgCode.ALERT_ID, stationAlertCodes],
    [StationArgCode.ERROR_ID, stationErrorCodes],
]);

/**
 * Returns the code of a known argument value
 */
export function getKnownValueById(
    argCode: string,
    argValue: string | null
): string | null {
    if (isNil(argValue)) {
        return null;
    }

    const dict = stationKnownValues.get(argCode);
    if (isNil(dict)) {
        return null;
    }

    const value = dict.get(argValue);
    return isNil(value) ? null : value;
}
