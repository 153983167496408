import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import {
    ErrorBoundary,
    LoadingIndicator,
    setDocumentTitle,
} from "../../components";
import { ApplicationBar, LoginForm } from "./parts";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        root: {
            marginTop: "16px",
            marginBottom: "16px",
        },
    });
});

export function LoginScreen() {
    const classes = useStyles();

    useEffect(function () {
        setDocumentTitle("Login");
    });

    return (
        <Fragment>
            <ApplicationBar title="Login" />
            <Grid
                className={classes.root}
                container
                direction="column"
                alignItems="flex-start"
                spacing={1}
            >
                <ErrorBoundary>
                    <React.Suspense fallback={<LoadingIndicator />}>
                        <Grid item>
                            <LoginForm />
                        </Grid>
                    </React.Suspense>
                </ErrorBoundary>
            </Grid>
        </Fragment>
    );
}
