import { ILexingError, IRecognitionException } from "chevrotain";
import { isObject } from "../../helpers";

export interface StationProtocolErrorInfo {
    lexingErrors: ILexingError[];
    parsingErrors: IRecognitionException[];
}

/**
 * An error encountered while parsing a station message
 */
export class StationProtocolError extends Error {
    private _info: StationProtocolErrorInfo;

    public constructor(options: StationProtocolErrorInfo) {
        let lexingErrors: ILexingError[];
        let parsingErrors: IRecognitionException[];

        if (!isObject(options)) {
            lexingErrors = [];
            parsingErrors = [];
        } else {
            lexingErrors = Array.isArray(options.lexingErrors)
                ? options.lexingErrors.slice()
                : [];
            parsingErrors = Array.isArray(options.parsingErrors)
                ? options.parsingErrors.slice()
                : [];
        }

        const messages = ["Station protocol message parsing failed."];

        if (lexingErrors.length > 0) {
            messages.push(
                ...lexingErrors.map(function (err) {
                    return err.message;
                })
            );
        } else if (parsingErrors.length > 0) {
            messages.push(
                ...parsingErrors.map(function (err) {
                    return err.message;
                })
            );
        }

        // 'Error' breaks the prototype chain here
        super(messages.join("\n"));

        // Restore the prototype chain
        Object.setPrototypeOf(this, new.target.prototype);

        this._info = { lexingErrors, parsingErrors };
    }

    /**
     * Returns the parsing errors
     */
    public getInfo(): StationProtocolErrorInfo {
        return this._info;
    }
}
