import { isNil, isNonEmptyString, isNumber, isObject } from "./helpers";
import { fromStatusCode, HttpStatus } from "./httpStatus";

/**
 * Details of an error
 *
 * @example
 * ```ts
 *  const details = {
 *    "domain": "users",
 *    "scope": "profile",
 *    "statusCode": 400,
 *    "phrase": "Bad Request",
 *    "error": "bad_request",
 *    "reason": "invalid_argument",
 *    "message": "The parameter `id` is invalid."
 * };
 * ```
 */
export interface ErrorReport {
    /** The error domain */
    domain: string;

    /** The scope within the domain */
    scope: string;

    /** The HTTP status code */
    statusCode: number;

    /** The HTTP status phrase */
    phrase: string;

    /** The status code as a literal code */
    error: string;

    /** The reason of the error as a literal code */
    reason: string;

    /** A descriptive message */
    message: string;
}

/**
 * Parses the details of an error, providing the missing properties.
 *
 * @example
 * ```ts
 *  parseError({
 *    "domain": "users",
 *    "scope": "profile",
 *    "statusCode": 400,
 *    "reason": "invalid_argument",
 *    "message": "The parameter `id` is invalid."
 * });
 * ```
 *
 * @param options: The error details
 * @returns The error report
 */
export function parseError(options: unknown): ErrorReport {
    let statusCode: any;
    let phrase: any;
    let domain: any;
    let scope: any;
    let message: any;
    let error: any;
    let reason: any;

    if (isObject(options)) {
        ({ domain, scope, statusCode, phrase, error, reason, message } =
            options as Record<string, any>);

        statusCode = Number(statusCode);
    } else if (isNumber(options)) {
        statusCode = options;
    }

    if (!isNonEmptyString(domain)) {
        domain = "unspecified";
    }

    if (!isNonEmptyString(scope)) {
        scope = "unspecified";
    }

    if (!isNonEmptyString(reason)) {
        reason = "unspecified";
    }

    if (!isFinite(statusCode)) {
        statusCode = 500;
    }

    statusCode = Math.floor(statusCode);

    let httpStatus = fromStatusCode(statusCode);

    if (isNil(httpStatus)) {
        httpStatus = fromStatusCode(500) as HttpStatus;

        statusCode = httpStatus.statusCode;
    }

    if (!isNonEmptyString(error)) {
        error = httpStatus.code;
    }

    if (!isNonEmptyString(phrase)) {
        phrase = httpStatus.phrase;
    }

    if (!isNonEmptyString(message)) {
        message = phrase;
    }

    return {
        domain,
        scope,
        statusCode,
        phrase,
        error,
        reason,
        message,
    };
}
