import { isNonEmptyString } from "../../../common";

export interface BikeRFIDInputValues {
    bikeRFID: string;
}

export function validateBikeRFID(
    values: BikeRFIDInputValues,
    errors: Partial<BikeRFIDInputValues>
): void {
    if (!isNonEmptyString(values.bikeRFID)) {
        errors.bikeRFID = "Required";
    } else if (values.bikeRFID.length > 40) {
        errors.bikeRFID = "The text is too long.";
    } else if (!/^[0-9a-fA-F]+$/.test(values.bikeRFID)) {
        errors.bikeRFID = "Please enter an hexadecimal value.";
    }
}

export interface SlotInputValues {
    slotNumber: string;
}

export function validateSlot(
    values: SlotInputValues,
    errors: Partial<SlotInputValues>
): void {
    if (!isNonEmptyString(values.slotNumber)) {
        errors.slotNumber = "Required";
    } else if (values.slotNumber.length > 2) {
        errors.slotNumber = "The text is too long.";
    } else if (!/^[0-9]+$/.test(values.slotNumber)) {
        errors.slotNumber = "Please enter a numeric value.";
    }
}

export interface CardRFIDInputValues {
    cardRFID: string;
}

export function validateCardRFID(
    values: CardRFIDInputValues,
    errors: Partial<CardRFIDInputValues>
): void {
    if (!isNonEmptyString(values.cardRFID)) {
        errors.cardRFID = "Required";
    } else if (values.cardRFID.length > 40) {
        errors.cardRFID = "The text is too long.";
    } else if (!/^[0-9]+$/.test(values.cardRFID)) {
        errors.cardRFID = "Please enter a numeric value.";
    }
}

export interface AccountInputValues {
    account: string;
}

export function validateAccount(
    values: AccountInputValues,
    errors: Partial<AccountInputValues>
): void {
    if (!isNonEmptyString(values.account)) {
        errors.account = "Required";
    } else if (values.account.length > 40) {
        errors.account = "The text is too long.";
    } else if (!/^[0-9]+$/.test(values.account)) {
        errors.account = "Please enter a numeric value.";
    }
}

export interface PinInputValues {
    pin: string;
}

export function validatePin(
    values: PinInputValues,
    errors: Partial<PinInputValues>
): void {
    if (!isNonEmptyString(values.pin)) {
        errors.pin = "Required";
    } else if (values.pin.length > 40) {
        errors.pin = "The text is too long.";
    } else if (!/^[0-9]+$/.test(values.pin)) {
        errors.pin = "Please enter a numeric value.";
    }
}
