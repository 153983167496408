/** The station event types */
export enum StationEventTag {
    /**
     * Fired when the web socket connection has been connected, closed or is
     * reconnecting
     */
    WS = "WS",

    /** Fired when the station screen changes */
    UI = "UI",

    /** Fired when there is a configuration upgrade */
    Config = "Config",

    /** Fired when there is station connection state change */
    Connect = "Connect",

    /** Fired when a keep alive response is received */
    KeepAlive = "KeepAlive",

    /** Fired when there is station authentication state change */
    Auth = "Auth",

    /** Fired when a bike is docked */
    BikeArrive = "BikeArrive",

    /** Fired when a bike is removed */
    BikeRemove = "BikeRemove",

    /** Fired when a bike is not removed */
    BikeNotRemoved = "BikeNotRemoved",

    /** Fired when there is a bike checkout state change */
    BikeCheckout = "BikeCheckout",

    /** Fired when there is a lock state change */
    Lock = "Lock",

    /** Fired when a team member connects or disconnects */
    Team = "Team",

    /** Fired when the network state changes */
    Network = "Network",

    /** Fired when a station reboot has been requested */
    Reboot = "Reboot",
}

export interface StationEvent<T = unknown> {
    /** The event type */
    readonly eventType: string;

    /** The server event time stamp in milliseconds */
    readonly timeStamp: number;

    /** The event payload */
    readonly payload: T;
}

/** The `WS` event payload */
export interface WSEventPayload {
    readonly state: string;
}

export type WSEvent = StationEvent<WSEventPayload>;

/** The `UIChange` event payload */
export interface UIChangeEventPayload {
    readonly state: string;
}

export type UIChangeEvent = StationEvent<UIChangeEventPayload>;

/** The `Configuration` event payload */
export interface ConfigurationEventPayload {
    readonly state: string;
}

export type ConfigurationEvent = StationEvent<ConfigurationEventPayload>;

/** The `Connection` event payload */
export interface ConnectionEventPayload {
    readonly state: string;
}

export type ConnectionEvent = StationEvent<ConnectionEventPayload>;

/** The `Authentication` event payload */
export interface AuthenticationEventPayload {
    readonly state: string;
}

export type AuthenticationEvent = StationEvent<AuthenticationEventPayload>;

/** The `BikeArrive` event payload */
export interface BikeArriveEventPayload {
    readonly slotNumber: number;
}

export type BikeArriveEvent = StationEvent<BikeArriveEventPayload>;

/** The `BikeRemove` event payload */
export interface BikeRemoveEventPayload {
    readonly slotNumber: number;
}

export type BikeRemoveEvent = StationEvent<BikeRemoveEventPayload>;

/** The `BikeNotRemoved` event payload */
export interface BikeNotRemovedEventPayload {
    readonly slotNumber: number;
}

export type BikeNotRemovedEvent = StationEvent<BikeNotRemovedEventPayload>;

/** The `BikeCheckout` event payload */
export interface BikeCheckoutEventPayload {
    readonly state: string;
}

export type BikeCheckoutEvent = StationEvent<BikeCheckoutEventPayload>;

/** The `Team` event payload */
export interface TeamEventPayload {
    readonly state: string;

    readonly username: string;
}

export type TeamEvent = StationEvent<TeamEventPayload>;

/** The `Network` event payload */
export interface NetworkEventPayload {
    readonly state: string;
}

export type NetworkEvent = StationEvent<NetworkEventPayload>;

/** The `KeepAlive` event payload */
export interface KeepAliveEventPayload {
    readonly state: string;
}

export type KeepAliveEvent = StationEvent<KeepAliveEventPayload>;

/** The `Reboot` event payload */
export interface RebootEventPayload {
    readonly state: string;
}

export type RebootEvent = StationEvent<RebootEventPayload>;
