import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { setDocumentTitle } from "../../components";
import { useAmbient } from "../../context";
import { StationEvent, StationEventTag, UserProfile } from "../../models";
import {
    rebootRequestedState,
    screenTimeStampState,
    slotTimeStampState,
    stateTimeStampState,
    stationSpecState,
    teamTimeStampState,
} from "../../state";
import { ApplicationBar, StationPanel } from "./parts";

/**
 * The station screen route params
 */
export interface StationRouteParams {
    env: string;
    stationID: string;
}

/** Events that updates the station screen */
const screenEvents = new Set<string>([StationEventTag.UI]);

/** Events that updates the slots state */
const slotEvents = new Set<string>([
    StationEventTag.Lock,
    StationEventTag.BikeArrive,
    StationEventTag.BikeRemove,
    StationEventTag.BikeNotRemoved,
    StationEventTag.BikeCheckout,
]);

/** Events that updates the station state */
const stateEvents = new Set<string>([
    StationEventTag.WS,
    StationEventTag.Config,
    StationEventTag.Connect,
    StationEventTag.Auth,
    StationEventTag.UI,
    StationEventTag.Lock,
    StationEventTag.Network,
    StationEventTag.KeepAlive,
    StationEventTag.Reboot,
]);

/** Events that updates the team state */
const teamEvents = new Set<string>([StationEventTag.Team]);

export interface StationScreenProps {
    user: UserProfile;
}

export function StationScreen({ user }: StationScreenProps) {
    const { station, setStationSpec, getStationSpec } = useAmbient();

    const { env, stationID } = useParams<StationRouteParams>();

    const setStationSpecState = useSetRecoilState(stationSpecState);
    const setScreenTimeStamp = useSetRecoilState(screenTimeStampState);
    const setStateTimeStamp = useSetRecoilState(stateTimeStampState);
    const setSlotTimeStamp = useSetRecoilState(slotTimeStampState);
    const setTeamTimeStamp = useSetRecoilState(teamTimeStampState);
    const setRebootRequested = useSetRecoilState(rebootRequestedState);

    setStationSpec(env, parseInt(stationID, 10));

    const stationSpec = getStationSpec();

    const title = `${stationID} (${env})`;

    useEffect(function () {
        setDocumentTitle(title);

        setStationSpecState(stationSpec);

        const subscription = station.subscribe(
            stationSpec,
            function (evt: StationEvent) {
                const { eventType, timeStamp } = evt;

                //
                // Note that one event type can be related to one or more categories

                if (screenEvents.has(eventType)) {
                    setScreenTimeStamp(timeStamp);
                }

                if (slotEvents.has(eventType)) {
                    setSlotTimeStamp(timeStamp);
                }

                if (stateEvents.has(eventType)) {
                    setStateTimeStamp(timeStamp);

                    if (eventType === StationEventTag.Reboot) {
                        setRebootRequested(true);
                    }
                }

                if (teamEvents.has(eventType)) {
                    setTeamTimeStamp(timeStamp);
                }
            }
        );
        return function unsubscribe() {
            subscription.unsubscribe();
        };
    });

    return (
        <Fragment>
            <ApplicationBar title={title} user={user} />
            <StationPanel />
        </Fragment>
    );
}
