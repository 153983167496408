import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { isNonEmptyString, show } from "../../../common";
import { useAmbient } from "../../../context";
import { connectedMembersQuery, userQuery } from "../../../state";
import { makeMemberListItems, makeMemberStyleDefs } from "./members";

const useStyles = makeStyles(function (theme: Theme) {
    const memberStyles = makeMemberStyleDefs(theme);
    return createStyles({
        content: {
            minWidth: "480px",
            minHeight: "260px",
        },
        list: {
            maxHeight: "210px",
            overflow: "auto",
            marginTop: "8px",
            marginBottom: "16px",
        },
        notification: {
            marginTop: "24px",
        },
        ...memberStyles,
    });
});

export interface RebootFormProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export const RebootForm: React.FC<RebootFormProps> = ({
    isOpen,
    setIsOpen,
}: RebootFormProps) => {
    const classes = useStyles();

    const { station, stationSpec } = useAmbient();

    const user = useRecoilValue(userQuery);

    const members = useRecoilValue(connectedMembersQuery);

    const [errorMessage, setErrorMessage] = useState("");

    const [isSubmitting, setSubmitting] = useState(false);

    const [isConfirmed, setIsConfirmed] = useState(false);

    function handleCancel() {
        setErrorMessage("");
        setIsConfirmed(false);
        setIsOpen(false);
    }

    function submitForm() {
        setErrorMessage("");
        setIsConfirmed(false);
        station
            .rebootStation(stationSpec)
            .then(function () {
                setSubmitting(false);
                setIsOpen(false);
            })
            .catch(function (err) {
                setSubmitting(false);
                setErrorMessage(show(err));
            });
    }

    function handleChange(
        _evt: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void {
        setIsConfirmed(checked === true);
    }

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>Reboot Station</DialogTitle>
            <form>
                <DialogContent className={classes.content}>
                    <DialogContentText>
                        Request a station reboot.
                    </DialogContentText>
                    <Box>
                        The following team members are currently connected:
                    </Box>
                    <Box>
                        <List dense className={classes.list}>
                            {makeMemberListItems(user, members, classes)}
                        </List>
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Please confirm that you want to reboot the station."
                            control={
                                <Checkbox
                                    name="rebootConfirmed"
                                    onChange={handleChange}
                                    checked={isConfirmed}
                                />
                            }
                        />
                    </Box>
                    <Box className={classes.notification}>
                        {isSubmitting && <LinearProgress />}
                        {isNonEmptyString(errorMessage) && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={isSubmitting || !isConfirmed}
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                    <Button
                        color="default"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
