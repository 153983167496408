/**
 * The bike status values
 */
export enum BikeStatusTag {
    /** Bike is active */
    Active = "Active",
    /** Inactive bike */
    Provisional = "Provisional",
    /** Stolen bike */
    Stolen = "Stolen",
    /** Lost bike */
    Lost = "Lost",
    /** Broken bike */
    Broken = "Broken",
    /** Stored bike */
    Stored = "Stored",
    /** Bike needs repairing */
    To_Repair = "To_Repair",
    /** Archived bike */
    Archived = "Archived",
    /** Bike is Reserved */
    Reserved = "Reserved",
    /** Bike is lost by supervisor */
    LostSupervisor = "Lost Supervisor",
    /** RFID has been merged */
    RFIDMerged = "RFID Merged",
}

/**
 * The station where a bike is docked
 */
export interface BikeStation {
    /** The unique city ID */
    cityID: number;

    /** The unique station ID */
    stationID: number;

    /** The station code */
    stationCode: string;

    /** The unique slot ID */
    slotID: number;

    /** The station slot number */
    slotNumber: number;
}

/**
 * The subscriber that holds a bike
 */
export interface BikeSubscriber {
    /** The back-office unique subscriber ID */
    subscriberID: number;

    /** The BillingPlatform unique account ID */
    accountID: string;

    /** The card RFID */
    cardRFID: string | null;
}

/**
 * A bike tha is on-transit
 */
export interface BikeRide {
    /** The unique bike ride ID */
    bikeRideID: number;

    /** The bike checkout time, in extended ISO format */
    checkoutTime: string;

    /** The source station */
    sourceStation: BikeStation;

    /** The subscriber that holds the bike */
    subscriber: BikeSubscriber;
}

/**
 * The state of a bike
 */
export interface BikeState {
    /** The unique bike ID */
    bikeID: number;

    /** The bike RFID */
    bikeRFID: string;

    /** The bike status */
    bikeStatus: string;

    /** The broken count */
    brokenCount: number;

    /** The station where the bike is docked */
    station: BikeStation | null;

    /** The ride to which the bike has been assigned */
    ride: BikeRide | null;
}

/**
 * A station configured bike
 */
export interface BikeConfig {
    /** The unique bike ID */
    bikeID: number;

    /** The bike RFID */
    bikeRFID: string;
}

/**
 * Input to dock a bike
 */
export interface DockBikeInput {
    /** The bike RFID */
    bikeRFID: string;
}

/**
 * A bike docked in a slot
 */
export interface DockBikePayload {
    /** The slot number */
    slotNumber: number;

    /** The bike RFID */
    bikeRFID: string;
}

/**
 * A bike removed from a slot
 */
export interface BikeCheckoutPayload {
    /** The slot number */
    slotNumber: number;

    /** The bike RFID */
    bikeRFID: string;

    /** The back-office bike ride ID */
    actionID: number;
}
