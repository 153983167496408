import * as signalR from "@microsoft/signalr";
import ow from "ow";
import { Observable, Subject, Subscription } from "rxjs";
import { multicast, refCount } from "rxjs/operators";
import { StationEmulator, StationEmulatorOptions } from "..";
import {
    combineUrl,
    HttpStatusCode,
    isNil,
    isNonEmptyString,
    isNotNil,
    isObjectLike,
    parseError,
    show,
    SomeError,
} from "../../../common";
import {
    BikeCheckoutPayload,
    BikeConfig,
    BikeState,
    CardReaderInput,
    CardReaderPayload,
    DockBikePayload,
    FileSpec,
    KeyPadInput,
    KeyPadPayload,
    LockState,
    NetworkInput,
    NetworkPayload,
    SlotState,
    StationEvent,
    StationEventTag,
    StationRebootPayload,
    StationSpec,
    StationState,
    StationSummary,
    TeamMember,
    UserProfile,
} from "../../../models";
import { FetchProvider } from "../../fetch";
import { Logger } from "../../logger";

interface BlobResponse {
    contentType: string;
    body: Blob;
}

export class StationEmulatorApi implements StationEmulator {
    private readonly _logger: Logger;

    private readonly _fetch: FetchProvider;

    /** The reference counted observable over the SignalR events */
    private readonly _observables: Map<string, Observable<StationEvent>>;

    private _endpointUrl: string | null;

    /**
     * Constructs an instance of {@link StationEmulatorApi}
     */
    public constructor(logger: Logger, fetchProvider: FetchProvider) {
        ow(logger, ow.object);
        ow(fetchProvider, ow.object);

        this._fetch = fetchProvider;

        this._logger = logger;

        this._observables = new Map<string, Observable<StationEvent>>();

        this._endpointUrl = null;
    }

    /** Initializes the service */
    public init(options: StationEmulatorOptions): Promise<void> {
        ow(options, ow.object);

        const { endpointUrl } = options;

        ow(endpointUrl, ow.string.nonEmpty);

        if (isNotNil(this._endpointUrl)) {
            return Promise.reject(
                new Error(
                    "The station emulator service has already been initialized."
                )
            );
        }

        this._endpointUrl = endpointUrl;

        return Promise.resolve();
    }

    /** Returns the current user profile */
    public getUser(): Promise<UserProfile> {
        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(endpointUrl, "/api/v1/user/profile");

        return this.fetchJSON<UserProfile>(resourceUrl);
    }

    /** Returns the configured stations*/
    public getStations(): Promise<StationSummary[]> {
        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(endpointUrl, "/api/v1/stations");

        return this.fetchJSON<StationSummary[]>(resourceUrl);
    }

    /** Returns the team members connected to the specified station */
    public getConnectedMembers(station: StationSpec): Promise<TeamMember[]> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/team/connected`
        );

        return this.fetchJSON<TeamMember[]>(resourceUrl);
    }

    /** Returns the station screen image URL */
    public getScreenImageUrl(station: StationSpec, nonce: number): string {
        ow(station, ow.object);
        ow(nonce, ow.number);

        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        return combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/screen/image.png?nonce=${nonce}`
        );
    }

    /** Returns the station state */
    public getStationState(station: StationSpec): Promise<StationState> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/state`
        );

        return this.fetchJSON<StationState>(resourceUrl);
    }

    /** Returns the station details */
    public getStationSummary(station: StationSpec): Promise<StationSummary> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/summary`
        );

        return this.fetchJSON<StationSummary>(resourceUrl);
    }

    /** Returns the configured bikes */
    public getConfigBikes(station: StationSpec): Promise<BikeConfig[]> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/config/bikes`
        );

        return this.fetchJSON<BikeConfig[]>(resourceUrl);
    }

    /** Returns the station log files */
    public getLogFiles(station: StationSpec): Promise<FileSpec[]> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/log/files`
        );

        return this.fetchJSON<FileSpec[]>(resourceUrl);
    }

    /** Returns the specified log file content*/
    public async getLogFileContent(
        station: StationSpec,
        fileName: string
    ): Promise<string> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/log/files/${fileName}`
        );

        const { body } = await this.fetchBlob(resourceUrl);

        const text = await body.text();

        return text;
    }

    /** Returns the configured bikes state */
    public getBikes(station: StationSpec): Promise<BikeState[]> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/bikes`
        );

        return this.fetchJSON<BikeState[]>(resourceUrl);
    }

    /** Returns the slot locks state */
    public getLocks(station: StationSpec): Promise<LockState[]> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/slots`
        );

        return this.fetchJSON<LockState[]>(resourceUrl);
    }

    /** Returns the specified slot lock state */
    public getLock(
        station: StationSpec,
        slotNumber: number
    ): Promise<LockState> {
        ow(station, ow.object);
        ow(slotNumber, ow.number);

        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/slots/${slotNumber}`
        );

        return this.fetchJSON<LockState>(resourceUrl);
    }

    /** Returns the specified slot dock state */
    public getSlot(
        station: StationSpec,
        slotNumber: number
    ): Promise<SlotState> {
        ow(station, ow.object);
        ow(slotNumber, ow.number);

        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/slots/${slotNumber}/bike`
        );

        return this.fetchJSON<SlotState>(resourceUrl);
    }

    /** Removes the bike from the specified slot */
    public removeGrantedBike(
        station: StationSpec,
        slotNumber: number
    ): Promise<BikeCheckoutPayload> {
        ow(station, ow.object);
        ow(slotNumber, ow.number);

        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/slots/${slotNumber}/bike`
        );

        return this.fetchJSON<BikeCheckoutPayload>(resourceUrl, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        });
    }

    /** Docks a bike in the specified slot */
    public dockBike(
        station: StationSpec,
        slotNumber: number,
        bikeRFID: string
    ): Promise<DockBikePayload> {
        ow(station, ow.object);
        ow(slotNumber, ow.number);
        ow(bikeRFID, ow.string.nonEmpty);

        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/slots/${slotNumber}/bike`
        );

        return this.fetchJSON<DockBikePayload>(resourceUrl, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: [["Content-Type", "application/json"]],
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({ bikeRFID }),
        });
    }

    /** Simulates a card reader swipe */
    public simulateCardSwipe(
        station: StationSpec,
        input: CardReaderInput
    ): Promise<CardReaderPayload> {
        ow(station, ow.object);
        ow(input, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/cardreader/input`
        );

        return this.fetchJSON<CardReaderPayload>(resourceUrl, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: [["Content-Type", "application/json"]],
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(input),
        });
    }

    /** Simulates a keypad input */
    public simulateKeyPadInput(
        station: StationSpec,
        input: KeyPadInput
    ): Promise<KeyPadPayload> {
        ow(station, ow.object);
        ow(input, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/keypad/input`
        );

        return this.fetchJSON<KeyPadPayload>(resourceUrl, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: [["Content-Type", "application/json"]],
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(input),
        });
    }

    /** Updates the network state */
    public updateNetwork(
        station: StationSpec,
        input: NetworkInput
    ): Promise<NetworkPayload> {
        ow(station, ow.object);
        ow(input, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/network/state`
        );

        return this.fetchJSON<NetworkPayload>(resourceUrl, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: [["Content-Type", "application/json"]],
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(input),
        });
    }

    /** Request a station reboot */
    public rebootStation(station: StationSpec): Promise<StationRebootPayload> {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const resourceUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/reboot`
        );

        return this.fetchJSON<StationRebootPayload>(resourceUrl, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: [["Content-Type", "application/json"]],
            redirect: "follow",
            referrerPolicy: "no-referrer",
        });
    }

    /**
     * Subscribe to the station event stream
     * @param callback The callback invoked on event reception
     */
    public subscribe(
        station: StationSpec,
        callback: (value: StationEvent) => void
    ): Subscription {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);
        ow(callback, ow.function);

        const key = `${env}:${stationID}`;

        let observable = this._observables.get(key);
        if (isNil(observable)) {
            const connection = this.createConnection(station);

            observable = this.createObservable(key, connection);

            this._observables.set(key, observable);
        }

        return observable.subscribe(callback);
    }

    /**
     * Returns an observable over the SignalR events.
     * The SignalR connection  will be started when a subscriber subscribes
     * to the observable
     */
    private createObservable(
        key: string,
        connection: signalR.HubConnection
    ): Observable<StationEvent> {
        ow(key, ow.string.nonEmpty);
        ow(connection, ow.object);

        const source = new Observable<StationEvent>((subscriber) => {
            this._logger.info("Subscribe to the station event feed");

            connection.onclose((err) => {
                if (isNotNil(err)) {
                    this._logger.error(show(err), { error: err });
                }
                try {
                    subscriber.next({
                        eventType: StationEventTag.WS,
                        timeStamp: Date.now(),
                        payload: {
                            state: "close",
                        },
                    });
                } catch (err) {
                    this._logger.error(show(err), { error: err });
                }
            });

            connection.onreconnected((_connectionId) => {
                try {
                    subscriber.next({
                        eventType: StationEventTag.WS,
                        timeStamp: Date.now(),
                        payload: {
                            state: "reconnected",
                        },
                    });
                } catch (err) {
                    this._logger.error(show(err), { error: err });
                }
            });

            connection.onreconnecting((err) => {
                if (isNotNil(err)) {
                    this._logger.error(show(err), { error: err });
                }
                try {
                    subscriber.next({
                        eventType: StationEventTag.WS,
                        timeStamp: Date.now(),
                        payload: {
                            state: "reconnecting",
                        },
                    });
                } catch (err) {
                    this._logger.error(show(err), { error: err });
                }
            });

            connection.on(
                "receiveEvent",
                (
                    eventType: string,
                    timeStamp: number,
                    payload: string
                ): void => {
                    try {
                        var evt = this.readIncomingEvent(
                            eventType,
                            timeStamp,
                            payload
                        );

                        subscriber.next(evt);
                    } catch (err) {
                        this._logger.error(show(err), { error: err });
                    }
                }
            );

            connection
                .start()
                .then(() => {
                    this._logger.info("Connected to the station event feed");
                })
                .catch((err) => {
                    this._logger.error(show(err), { error: err });
                });

            // Provide a way of canceling and disposing the interval resource
            return () => {
                this._observables.delete(key);

                this._logger.info("Unsubscribe from the station event feed");

                connection.off("receiveEvent");

                connection
                    .stop()
                    .then(() => {
                        this._logger.info(
                            "Disconnected from the station event feed"
                        );
                    })
                    .catch((err) => {
                        this._logger.error(show(err), { error: err });
                    });
            };
        });

        //
        // Create the reference counted observable, will subscribe to the source
        // observable when the first subscriber subscribes, and will un-subscribe from
        // the source observable when the last subscriber un-subscribes.
        const subject = new Subject<StationEvent>();

        const refCounted = source.pipe(multicast(subject), refCount());

        return refCounted;
    }

    /**
     * Returns a SignalR connection to the emulator api
     */
    private createConnection(station: StationSpec): signalR.HubConnection {
        ow(station, ow.object);
        const { env, stationID } = station;
        ow(env, ow.string.nonEmpty);
        ow(stationID, ow.number);

        const endpointUrl = this.getEndpointUrl();

        const feedUrl = combineUrl(
            endpointUrl,
            `/api/v1/env/${env}/station/${stationID}/feed`
        );

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(feedUrl, signalR.HttpTransportType.WebSockets)
            .withAutomaticReconnect()
            .build();

        return connection;
    }

    /**
     * Reads an incoming SignalR event
     *
     * @param eventType The event type tag
     * @param payload The event payload
     */
    private readIncomingEvent(
        eventType: string,
        timeStamp: number,
        payload: string
    ): StationEvent {
        ow(eventType, ow.string.nonEmpty);
        ow(timeStamp, ow.number);
        ow(payload, ow.string.nonEmpty);

        const parsed = JSON.parse(payload);

        return {
            eventType,
            timeStamp,
            payload: parsed,
        };
    }

    /** Performs a JSON content api request  */
    private async fetchJSON<T extends object>(
        resourceUrl: string,
        options?: RequestInit
    ): Promise<T> {
        const fetchResource = this._fetch.useFetch();

        let response: Response;
        try {
            response = await fetchResource(resourceUrl, options);
        } catch (err) {
            this._logger.error(show(err), { error: err });

            throw new SomeError({
                domain: "station",
                scope: "api",
                statusCode: HttpStatusCode.ServiceUnavailable,
                reason: "request_failed",
                message:
                    `The service request for the specified resource (${resourceUrl}) failed. `.concat(
                        show(err)
                    ),
            });
        }

        let result: unknown;
        try {
            result = await response.json();

            if (!isObjectLike(result)) {
                throw new Error(
                    `An object was expected but instead received (${typeof result}).`
                );
            }
        } catch (err) {
            this._logger.error(show(err), { error: err });

            throw new SomeError({
                domain: "station",
                scope: "api",
                statusCode: HttpStatusCode.InternalServerError,
                reason: "invalid_response",
                message:
                    `The service returned an invalid response for the specified resource (${resourceUrl}). `.concat(
                        show(err)
                    ),
            });
        }

        if (!response.ok) {
            const err = new SomeError(parseError(result));

            this._logger.error(show(err), { error: err });

            throw err;
        }

        return result as T;
    }

    /** Performs an arbitrary content api request  */
    private async fetchBlob(
        resourceUrl: string,
        options?: RequestInit
    ): Promise<BlobResponse> {
        const fetchResource = this._fetch.useFetch();

        let response: Response;
        try {
            response = await fetchResource(resourceUrl, options);
        } catch (err) {
            this._logger.error(show(err), { error: err });

            throw new SomeError({
                domain: "station",
                scope: "api",
                statusCode: HttpStatusCode.ServiceUnavailable,
                reason: "request_failed",
                message:
                    `The service request for the specified resource (${resourceUrl}) failed. `.concat(
                        show(err)
                    ),
            });
        }

        const headers = response.headers;

        const contentTypeValue = headers.get("Content-Type");

        const contentType = isNonEmptyString(contentTypeValue)
            ? contentTypeValue
            : "";

        const body = await response.blob();

        if (!response.ok) {
            let info: any;
            try {
                if (contentType === "application/json") {
                    const text = await body.text();
                    info = JSON.parse(text);
                } else {
                    info = {
                        statusCode: response.status,
                        phrase: response.statusText,
                    };
                }
            } catch (err) {
                /* Do nothing */
            }

            const err = new SomeError(parseError(info));

            this._logger.error(show(err), { error: err });

            throw err;
        }

        return { contentType, body };
    }

    /** Returns the emulator API URL */
    private getEndpointUrl(): string {
        const endpointUrl = this._endpointUrl;
        if (isNil(endpointUrl)) {
            throw new Error(
                "The station emulator service has not been initialized."
            );
        }
        return endpointUrl;
    }
}
