import ow from "ow";
import { ConfigStore } from "..";
import { isNil, show } from "../../../common";

/**
 * A configuration store that loads values from the local environment.
 */
/* @injectable() */
export class LocalConfig implements ConfigStore {
    private readonly _config: Map<string, string>;

    /**
     * Constructs an instance of {@link LocalConfig}
     */
    public constructor() {
        this._config = new Map<string, string>();
    }

    /**
     * Gets the current value of the specified property.
     *
     * @param name The configuration property name
     */
    public get(name: string): string {
        ow(name, ow.string.nonEmpty);
        const value = this._config.get(name);
        if (isNil(value)) {
            throw new Error(
                `The specified configuration value is undefined (${name}).`
            );
        }
        return value;
    }

    /**
     * Tests whether the specified property is defined.
     *
     * @param name The configuration property name
     * @returns `true` if the property name is defined
     */
    public has(name: string): boolean {
        ow(name, ow.string.nonEmpty);
        return this._config.has(name);
    }

    /**
     * Sets the value of configuration property.
     *
     * @param name The configuration property name
     * @param value The configuration property value
     */
    public set(name: string, value: string | undefined): void {
        ow(name, ow.string.nonEmpty);
        if (isNil(value)) {
            this._config.delete(name);
        } else {
            this._config.set(name, show(value));
        }
    }

    /**
     * Merges the specified object properties and values into the configuration
     * store
     */
    public merge(source: Record<string, string>): void {
        ow(source, ow.object);
        const props = Object.entries(source);
        for (const [name, value] of props) {
            this._config.set(name, show(value));
        }
    }

    /**
     * Exports all the properties, the keys and their current values, as
     * a JSON compliant object.
     */
    public toJSON(): Record<string, string> {
        const props = this._config.entries();
        return Object.fromEntries(props);
    }
}
