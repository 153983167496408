import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";

export const defaultTheme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: red,
    },
});
