import { FetchProvider } from "..";

export class PlatformFetch implements FetchProvider {
    /**
     * Returns a function that, when invoked, starts the process of fetching a resource from
     * the network, returning a promise which is fulfilled once
     * the response is available
     * See https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
     */
    public useFetch(): (
        resource: RequestInfo,
        init?: RequestInit
    ) => Promise<Response> {
        return fetch;
    }
}
