import { isNil, isNumber, isString } from "./helpers";

export enum HttpStatusCode {
    /**
     * The request has been received but not yet acted upon. It is non-committal,
     * meaning that there is no way in HTTP to later send an asynchronous response
     * indicating the outcome of processing the request. It is intended for cases
     * where another process or server handles the request, or for batch processing.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.3.3}
     */
    Accepted = 202,
    /**
     * This error response means that the server, while working as a gateway to get
     * a response needed to handle the request, got an invalid response.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.3}
     */
    BadGateway = 502,
    /**
     * This response means that server could not understand the request due to invalid syntax.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.1}
     */
    BadRequest = 400,
    /**
     * This response is sent when a request conflicts with the current state of the server.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.8}
     */
    Conflict = 409,
    /**
     * This interim response indicates that everything so far is OK and that the
     * client should continue with the request or ignore it if it is already
     * finished.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.2.1}
     */
    Continue = 100,
    /**
     * The request has succeeded and a new resource has been created as a result of
     * it. This is typically the response sent after a PUT request.  See {@link
     *
     * https://tools.ietf.org/html/rfc7231#section-6.3.2}
     */
    Created = 201,
    /**
     * This response code means the expectation indicated by the Expect request
     * header field can't be met by the server.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.14}
     */
    ExpectationFailed = 417,
    /**
     * The request failed due to failure of a previous request.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.5}
     */
    FailedDependency = 424,
    /**
     * The client does not have access rights to the content, i.e. they are
     * unauthorized, so server is rejecting to give proper response. Unlike 401, the
     * client's identity is known to the server.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.3}
     */
    Forbidden = 403,
    /**
     * This error response is given when the server is acting as a gateway and
     * cannot get a response in time.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.5}
     */
    GatewayTimeout = 504,
    /**
     * This response would be sent when the requested content has been permenantly
     * deleted from server, with no forwarding address. Clients are expected to
     * remove their caches and links to the resource. The HTTP specification intends
     * this status code to be used for "limited-time, promotional services". APIs
     * should not feel compelled to indicate resources that have been deleted with
     * this status code.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.9}
     */
    Gone = 410,
    /**
     * The HTTP version used in the request is not supported by the server.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.6}
     */
    HttpVersionNotSupported = 505,
    /**
     * Any attempt to brew coffee with a teapot should result in the error code "418
     * I'm a teapot". The resulting entity body MAY be short and stout.
     *
     * See {@link https://tools.ietf.org/html/rfc2324#section-2.3.2}
     */
    ImATeapot = 418,
    /**
     * UNOFFICIAL
     */
    InsufficientSpaceOnResource = 419,
    /**
     * The server has an internal configuration error: the chosen variant resource
     * is configured to engage in transparent content negotiation itself, and is
     * therefore not a proper end point in the negotiation process.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.6}
     */
    InsufficientStorage = 507,
    /**
     * The server has encountered a situation it doesn't know how to handle.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.1}
     */
    InternalServerError = 500,
    /**
     * Server rejected the request because the Content-Length header field is not
     * defined and the server requires it.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.10}
     */
    LengthRequired = 411,
    /**
     * The resource that is being accessed is locked.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.4}
     */
    Locked = 423,
    /**
     * A deprecated response used by the Spring Framework when a method has failed.
     * @deprecated
     */
    MethodFailure = 420,
    /**
     * The request method is known by the server but has been disabled and cannot be
     * used. For example, an API may forbid DELETE-ing a resource. The two mandatory
     * methods, GET and HEAD, must never be disabled and should not return this
     * error code.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.5}
     */
    MethodNotAllowed = 405,
    /**
     * This response code means that URI of requested resource has been
     * changed. Probably, new URI would be given in the response.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.2}
     */
    MovedPermanently = 301,
    /**
     * This response code means that URI of requested resource has been changed
     * temporarily. New changes in the URI might be made in the future. Therefore,
     * this same URI should be used by the client in future requests.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.3}
     */
    MovedTemporarily = 302,
    /**
     * A Multi-Status response conveys information about multiple resources in
     * situations where multiple status codes might be appropriate.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.2}
     */
    MultiStatus = 207,
    /**
     * The request has more than one possible responses. User-agent or user should
     * choose one of them. There is no standardized way to choose one of the
     * responses.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.1}
     */
    MultipleChoices = 300,
    /**
     * The 511 status code indicates that the client needs to authenticate to gain
     * network access.
     *
     * See {@link https://tools.ietf.org/html/rfc6585#section-6}
     */
    NetworkAuthenticationRequired = 511,
    /**
     * There is no content to send for this request, but the headers may be
     * useful. The user-agent may update its cached headers for this resource with
     * the new ones.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.3.5}
     */
    NoContent = 204,
    /**
     * This response code means returned meta-information set is not exact set as
     * available from the origin server, but collected from a local or a third party
     * copy. Except this condition, 200 OK response should be preferred instead of
     * this response.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.3.4}
     */
    NonAuthoritativeInformation = 203,
    /**
     * This response is sent when the web server, after performing server-driven
     * content negotiation, doesn't find any content following the criteria given by
     * the user agent.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.6}
     */
    NotAcceptable = 406,
    /**
     * The server can not find requested resource. In the browser, this means the
     * URL is not recognized. In an API, this can also mean that the endpoint is
     * valid but the resource itself does not exist. Servers may also send this
     * response instead of 403 to hide the existence of a resource from an
     * unauthorized client. This response code is probably the most famous one due
     * to its frequent occurence on the web.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.4}
     */
    NotFound = 404,
    /**
     * The request method is not supported by the server and cannot be handled. The
     * only methods that servers are required to support (and therefore that must
     * not return this code) are GET and HEAD.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.2}
     */
    NotImplemented = 501,
    /**
     * This is used for caching purposes. It is telling to client that response has
     * not been modified. So, client can continue to use same cached version of
     * response.
     *
     * See {@link https://tools.ietf.org/html/rfc7232#section-4.1}
     */
    NotModified = 304,
    /**
     * The request has succeeded. The meaning of a success varies depending on the HTTP method:
     * GET: The resource has been fetched and is transmitted in the message body.
     * HEAD: The entity headers are in the message body.
     * POST: The resource describing the result of the action is transmitted in the message body.
     * TRACE: The message body contains the request message as received by the server
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.3.1}
     */
    Ok = 200,
    /**
     * This response code is used because of range header sent by the client to
     * separate download into multiple streams.
     *
     * See {@link https://tools.ietf.org/html/rfc7233#section-4.1}
     */
    PartialContent = 206,
    /**
     * This response code is reserved for future use. Initial aim for creating this
     * code was using it for digital payment systems however this is not used
     * currently.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.2}
     */
    PaymentRequired = 402,
    /**
     * This means that the resource is now permanently located at another URI,
     * specified by the Location: HTTP Response header. This has the same semantics
     * as the 301 Moved Permanently HTTP response code, with the exception that the
     * user agent must not change the HTTP method used: if a POST was used in the
     * first request, a POST must be used in the second request.
     *
     * See {@link https://tools.ietf.org/html/rfc7538#section-3}
     */
    PermanentRedirect = 308,
    /**
     * The client has indicated preconditions in its headers which the server does not meet.
     *
     * See {@link https://tools.ietf.org/html/rfc7232#section-4.2}
     */
    PreconditionFailed = 412,
    /**
     * The origin server requires the request to be conditional. Intended to prevent
     * the 'lost update' problem, where a client GETs a resource's state, modifies
     * it, and PUTs it back to the server, when meanwhile a third party has modified
     * the state on the server, leading to a conflict.
     *
     * See {@link https://tools.ietf.org/html/rfc6585#section-3}
     */
    PreconditionRequired = 428,
    /**
     * This code indicates that the server has received and is processing the
     * request, but no response is available yet.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.1}
     */
    Processing = 102,
    /**
     * See {@link https://tools.ietf.org/html/rfc7235#section-3.2}
     *
     * This is similar to 401 but authentication is needed to be done by a proxy.
     */
    ProxyAuthenticationRequired = 407,
    /**
     * The server is unwilling to process the request because its header fields are
     * too large. The request MAY be resubmitted after reducing the size of the
     * request header fields.
     *
     * See {@link https://tools.ietf.org/html/rfc6585#section-5}
     */
    RequestHeaderFieldsTooLarge = 431,
    /**
     * This response is sent on an idle connection by some servers, even without any
     * previous request by the client. It means that the server would like to shut
     * down this unused connection. This response is used much more since some
     * browsers, like Chrome, Firefox 27+, or IE9, use HTTP pre-connection
     * mechanisms to speed up surfing. Also note that some servers merely shut down
     * the connection without sending this message.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.7}
     */
    RequestTimeout = 408,
    /**
     * Request entity is larger than limits defined by server, the server might
     * close the connection or return an Retry-After header field.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.11}
     */
    RequestTooLong = 413,
    /**
     * The URI requested by the client is longer than the server is willing to
     * interpret.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.12}
     */
    RequestUriTooLong = 414,
    /**
     * The range specified by the Range header field in the request can't be
     * fulfilled, it's possible that the range is outside the size of the target
     * URI's data.
     *
     * See {@link https://tools.ietf.org/html/rfc7233#section-4.4}
     */
    RequestedRangeNotSatisfiable = 416,
    /**
     * This response code is sent after accomplishing request to tell user agent
     * reset document view which sent this request.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.3.6}
     */
    ResetContent = 205,
    /**
     * Server sent this response to directing client to get requested resource to
     * another URI with an GET request.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.4}
     */
    SeeOther = 303,
    /**
     * The server is not ready to handle the request. Common causes are a server
     * that is down for maintenance or that is overloaded. Note that together with
     * this response, a user-friendly page explaining the problem should be
     * sent. This responses should be used for temporary conditions and the
     * Retry-After: HTTP header should, if possible, contain the estimated time
     * before the recovery of the service. The webmaster must also take care about
     * the caching-related headers that are sent along with this response, as these
     * temporary condition responses should usually not be cached.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.6.4}
     */
    ServiceUnavailable = 503,
    /**
     * This code is sent in response to an Upgrade request header by the client, and
     * indicates the protocol the server is switching too.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.2.2}
     */
    SwitchingProtocols = 101,
    /**
     * Server sent this response to directing client to get requested resource to
     * another URI with same method that used prior request. This has the same
     * semantic than the 302 Found HTTP response code, with the exception that the
     * user agent must not change the HTTP method used: if a POST was used in the
     * first request, a POST must be used in the second request.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.7}
     */
    TemporaryRedirect = 307,
    /**
     * The user has sent too many requests in a given amount of time ("rate
     * limiting").
     *
     * See {@link https://tools.ietf.org/html/rfc6585#section-4}
     */
    TooManyRequests = 429,
    /**
     * Although the HTTP standard specifies "unauthorized", semantically this
     * response means "unauthenticated". That is, the client must authenticate
     * itself to get the requested response.
     *
     * See {@link https://tools.ietf.org/html/rfc7235#section-3.1}
     */
    Unauthorized = 401,
    /**
     * The request was well-formed but was unable to be followed due to semantic
     * errors.
     *
     * See {@link https://tools.ietf.org/html/rfc2518#section-10.3}
     */
    UnprocessableEntity = 422,
    /**
     * The media format of the requested data is not supported by the server, so the
     * server is rejecting the request.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.5.13}
     */
    UnsupportedMediaType = 415,
    /**
     * Was defined in a previous version of the HTTP specification to indicate that
     * a requested response must be accessed by a proxy. It has been deprecated due
     * to security concerns regarding in-band configuration of a proxy.
     *
     * See {@link https://tools.ietf.org/html/rfc7231#section-6.4.6}
     * @deprecated
     */
    UseProxy = 305,
}

/**
 * HTTP status codes documentation
 *
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 */
export interface HttpStatus {
    /** The HTTP status code */
    statusCode: number;

    /** The HTTP status code as a string literal */
    code: string;

    /** The HTTP status phrase  */
    phrase: string;
}

const CODES = new Map<number, HttpStatus>([
    //
    // 1XX Informational
    [
        100,
        {
            statusCode: 100,
            code: "continue",
            phrase: "Continue",
        },
    ],
    [
        101,
        {
            statusCode: 101,
            code: "switching_protocols",
            phrase: "Switching Protocols",
        },
    ],
    //
    // 2XX Successful
    [
        200,
        {
            statusCode: 200,
            code: "ok",
            phrase: "OK",
        },
    ],
    [
        201,
        {
            statusCode: 201,
            code: "created",
            phrase: "Created",
        },
    ],
    [
        202,
        {
            statusCode: 202,
            code: "accepted",
            phrase: "Accepted",
        },
    ],
    [
        203,
        {
            statusCode: 203,
            code: "non_authoritative_information",
            phrase: "Non-Authoritative Information",
        },
    ],
    [
        204,
        {
            statusCode: 204,
            code: "no_content",
            phrase: "No Content",
        },
    ],
    [
        205,
        {
            statusCode: 205,
            code: "reset_content",
            phrase: "Reset Content",
        },
    ],
    [
        206,
        {
            statusCode: 206,
            code: "partial_content",
            phrase: "Partial Content",
        },
    ],
    //
    // 3XX Redirection
    [
        300,
        {
            statusCode: 300,
            code: "multiple_choices",
            phrase: "Multiple Choices",
        },
    ],
    [
        301,
        {
            statusCode: 301,
            code: "moved_permanently",
            phrase: "Moved Permanently",
        },
    ],
    [
        302,
        {
            statusCode: 302,
            code: "found",
            phrase: "Found",
        },
    ],
    [
        303,
        {
            statusCode: 303,
            code: "see_other",
            phrase: "See Other",
        },
    ],
    [
        304,
        {
            statusCode: 304,
            code: "not_modified",
            phrase: "Not Modified",
        },
    ],
    [
        305,
        {
            statusCode: 305,
            code: "use_proxy",
            phrase: "Use Proxy",
        },
    ],
    [
        307,
        {
            statusCode: 307,
            code: "temporary_redirect",
            phrase: "Temporary Redirect",
        },
    ],
    //
    // 4XX Client Error
    [
        400,
        {
            statusCode: 400,
            code: "bad_request",
            phrase: "Bad Request",
        },
    ],
    [
        401,
        {
            statusCode: 401,
            code: "unauthorized",
            phrase: "Unauthorized",
        },
    ],
    [
        402,
        {
            statusCode: 402,
            code: "payment_required",
            phrase: "Payment Required",
        },
    ],
    [
        403,
        {
            statusCode: 403,
            code: "forbidden",
            phrase: "Forbidden",
        },
    ],
    [
        404,
        {
            statusCode: 404,
            code: "not_found",
            phrase: "Not Found",
        },
    ],
    [
        405,
        {
            statusCode: 405,
            code: "method_not_allowed",
            phrase: "Method Not Allowed",
        },
    ],
    [
        406,
        {
            statusCode: 406,
            code: "not_acceptable",
            phrase: "Not Acceptable",
        },
    ],
    [
        407,
        {
            statusCode: 407,
            code: "proxy_authentication_required",
            phrase: "Proxy Authentication Required",
        },
    ],
    [
        408,
        {
            statusCode: 408,
            code: "request_timeout",
            phrase: "Request Timeout",
        },
    ],
    [
        409,
        {
            statusCode: 409,
            code: "conflict",
            phrase: "Conflict",
        },
    ],
    [
        410,
        {
            statusCode: 410,
            code: "gone",
            phrase: "Gone",
        },
    ],
    [
        411,
        {
            statusCode: 411,
            code: "length_required",
            phrase: "Length Required",
        },
    ],
    [
        412,
        {
            statusCode: 412,
            code: "precondition_failed",
            phrase: "Precondition Failed",
        },
    ],
    [
        413,
        {
            statusCode: 413,
            code: "payload_too_large",
            phrase: "Payload Too Large",
        },
    ],
    [
        414,
        {
            statusCode: 414,
            code: "uri_too_long",
            phrase: "URI Too Long",
        },
    ],
    [
        415,
        {
            statusCode: 415,
            code: "unsupported_media_type",
            phrase: "Unsupported Media Type",
        },
    ],
    [
        416,
        {
            statusCode: 416,
            code: "range_not_satisfiable",
            phrase: "Range Not Satisfiable",
        },
    ],
    [
        417,
        {
            statusCode: 417,
            code: "expectation_failed",
            phrase: "Expectation Failed",
        },
    ],
    [
        418,
        {
            statusCode: 418,
            code: "im_a_teapot",
            phrase: "I'm a teapot",
        },
    ],
    [
        426,
        {
            statusCode: 426,
            code: "upgrade_required",
            phrase: "Upgrade Required",
        },
    ],
    //
    // 5XX Server Error
    [
        500,
        {
            statusCode: 500,
            code: "internal_server_error",
            phrase: "Internal Server Error",
        },
    ],
    [
        501,
        {
            statusCode: 501,
            code: "not_implemented",
            phrase: "Not Implemented",
        },
    ],
    [
        502,
        {
            statusCode: 502,
            code: "bad_gateway",
            phrase: "Bad Gateway",
        },
    ],
    [
        503,
        {
            statusCode: 503,
            code: "service_unavailable",
            phrase: "Service Unavailable",
        },
    ],
    [
        504,
        {
            statusCode: 504,
            code: "gateway_timeout",
            phrase: "Gateway Time-out",
        },
    ],
    [
        505,
        {
            statusCode: 505,
            code: "http_version_not_supported",
            phrase: "HTTP Version Not Supported",
        },
    ],
    //
    // Unofficial
    [
        102,
        {
            statusCode: 102,
            code: "processing",
            phrase: "Processing",
        },
    ],
    [
        207,
        {
            statusCode: 207,
            code: "multi_status",
            phrase: "Multi-Status",
        },
    ],
    [
        226,
        {
            statusCode: 226,
            code: "im_used",
            phrase: "IM Used",
        },
    ],
    [
        308,
        {
            statusCode: 308,
            code: "permanent_redirect",
            phrase: "Permanent Redirect",
        },
    ],
    [
        422,
        {
            statusCode: 422,
            code: "unprocessable_entity",
            phrase: "Unprocessable Entity",
        },
    ],
    [
        423,
        {
            statusCode: 423,
            code: "locked",
            phrase: "Locked",
        },
    ],
    [
        424,
        {
            statusCode: 424,
            code: "failed_dependency",
            phrase: "Failed Dependency",
        },
    ],
    [
        428,
        {
            statusCode: 428,
            code: "precondition_required",
            phrase: "Precondition Required",
        },
    ],
    [
        429,
        {
            statusCode: 429,
            code: "too_many_requests",
            phrase: "Too Many Requests",
        },
    ],
    [
        431,
        {
            statusCode: 431,
            code: "request_header_fields_too_large",
            phrase: "Request Header Fields Too Large",
        },
    ],
    [
        449,
        {
            statusCode: 449,
            code: "retry_with",
            phrase: "Retry With",
        },
    ],
    [
        451,
        {
            statusCode: 451,
            code: "unavailable_for_legal_reasons",
            phrase: "Unavailable For Legal Reasons",
        },
    ],
    [
        506,
        {
            statusCode: 506,
            code: "variant_also_negotiates",
            phrase: "Variant Also Negotiates",
        },
    ],
    [
        507,
        {
            statusCode: 507,
            code: "insufficient_storage",
            phrase: "Insufficient Storage",
        },
    ],
    [
        511,
        {
            statusCode: 511,
            code: "network_authentication_required",
            phrase: "Network Authentication Required",
        },
    ],
]);

/**
 * Returns the specified HTTP status code documentation
 *
 * @param statusCode The HTTP status code
 * @returns The status code documentation or `null`
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fromStatusCode(statusCode: any): HttpStatus | undefined {
    if (isNumber(statusCode)) {
        statusCode = Math.floor(statusCode);
    } else if (isString(statusCode)) {
        statusCode = parseInt(statusCode, 10);
    }

    if (!Number.isFinite(statusCode)) {
        return undefined;
    }

    const found = CODES.get(statusCode);

    if (isNil(found)) {
        return undefined;
    }

    return { ...found };
}
