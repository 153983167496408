import React from "react";
import { invariant } from "ts-invariant";
import { isNotNil } from "../common/helpers";
import { Ambient } from "./ambient";
import { getAmbientContext, setAmbient } from "./ambientContext";

/** The Ambient provider component properties */
export interface AmbientProviderProps {
    ambient: Ambient;
    children: React.ReactNode | React.ReactNode[] | null;
}

/**
 * A component that allows consuming components to subscribe to the
 * runtime context changes.
 */
export const AmbientProvider: React.FC<AmbientProviderProps> = function ({
    ambient,
    children,
}) {
    const AmbientContext = getAmbientContext();
    return (
        <AmbientContext.Consumer>
            {(context = {}): React.ReactNode => {
                if (isNotNil(ambient) && context.ambient !== ambient) {
                    context = Object.assign({}, context, { ambient });

                    setAmbient(ambient);
                }

                invariant(
                    isNotNil(context.ambient),
                    "AmbientProvider was not passed an Ambient instance. " +
                        "Please make sure you pass a valid instance via the `ambient` prop."
                );

                return (
                    <AmbientContext.Provider value={context}>
                        {children}
                    </AmbientContext.Provider>
                );
            }}
        </AmbientContext.Consumer>
    );
};
