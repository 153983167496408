import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { LockState, LockStateTag } from "../../../models";
import { stationSlotsQuery } from "../../../state";
import { DockBikeForm } from "./dockBikeForm";
import { RemoveBikeForm } from "./removeBikeForm";
import { SlotBike } from "./types";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        table: {
            minWidth: "850px",
        },
        slot: {
            display: "inline-block",
            fontSize: "1.1em",
            fontWeight: "bold",
        },
        state: {
            display: "inline-block",
            marginRight: "8px",
            float: "left",
        },
        action: {
            display: "inline-block",
            marginRight: "8px",
        },
        led: {
            color: "#4caf50",
        },
        broken: {
            color: red[800],
        },
    });
});

type ClassDefs = ReturnType<typeof useStyles>;

export interface SlotListItemProps {
    classes: ClassDefs;
    slot: LockState;
    setSelectedSlot: (value: SlotBike) => void;
    isRemoveOpen: boolean;
    setIsRemoveOpen: (value: boolean) => void;
    isDockOpen: boolean;
    setIsDockOpen: (value: boolean) => void;
}

function SlotListItem({
    slot,
    classes,
    setSelectedSlot,
    isRemoveOpen,
    setIsRemoveOpen,
    isDockOpen,
    setIsDockOpen,
}: SlotListItemProps) {
    const isSlotOpen = slot.isOpen;
    const isSlotEmpty = slot.isEmpty;
    const hasBrokenBike = slot.lockState === LockStateTag.BROKEN_BIKE;

    function handleRemove() {
        setSelectedSlot({
            slotNumber: slot.slotNumber,
            bikeRFID: slot.bikeRFID ?? "",
        });
        setIsRemoveOpen(true);
    }

    function handleDock() {
        setSelectedSlot({
            slotNumber: slot.slotNumber,
            bikeRFID: slot.bikeRFID ?? "",
        });
        setIsDockOpen(true);
    }

    return (
        <TableRow>
            <TableCell>
                <Box className={classes.slot}>{slot.slotNumber}</Box>
            </TableCell>
            <TableCell>
                <Box
                    className={classes.state.concat(isSlotOpen ? " blink" : "")}
                >
                    <Icon
                        className={hasBrokenBike ? classes.broken : classes.led}
                        title={
                            hasBrokenBike
                                ? "Bike broken or provisional"
                                : isSlotEmpty || isSlotOpen
                                ? "Open"
                                : "Closed"
                        }
                    >
                        stop_circle
                    </Icon>
                </Box>
            </TableCell>
            <TableCell>
                <Box className={classes.state}>
                    <Icon title={isSlotEmpty || isSlotOpen ? "Open" : "Closed"}>
                        {isSlotEmpty || isSlotOpen ? "lock_open" : "lock"}
                    </Icon>
                </Box>
                <Box className={classes.state}>
                    <Icon title={isSlotEmpty ? "Empty" : "Occupied"}>
                        {isSlotEmpty ? "check" : "pedal_bike"}
                    </Icon>
                </Box>
            </TableCell>
            <TableCell className="code">
                {isSlotEmpty ? "EMPTY" : slot.bikeRFID}
            </TableCell>
            <TableCell>
                <Box className={classes.action}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        title="Remove the bike from the station"
                        disabled={
                            !isSlotOpen ||
                            isSlotEmpty ||
                            isRemoveOpen ||
                            isDockOpen
                        }
                        onClick={handleRemove}
                    >
                        Pick
                    </Button>
                </Box>
                <Box className={classes.action}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        title="Return the bike to the station"
                        disabled={!isSlotEmpty || isRemoveOpen || isDockOpen}
                        onClick={handleDock}
                    >
                        Dock
                    </Button>
                </Box>
            </TableCell>
        </TableRow>
    );
}

export function SlotList() {
    const classes = useStyles();

    const slots = useRecoilValue(stationSlotsQuery);

    const [selectedSlot, setSelectedSlot] = useState<SlotBike>(function () {
        return {
            slotNumber: 0,
            bikeRFID: "",
        };
    });
    const [isRemoveOpen, setIsRemoveOpen] = useState(false);

    const [isDockOpen, setIsDockOpen] = useState(false);

    return (
        <TableContainer>
            <RemoveBikeForm
                isOpen={isRemoveOpen}
                setIsOpen={setIsRemoveOpen}
                slot={selectedSlot}
            />
            <DockBikeForm
                isOpen={isDockOpen}
                setIsOpen={setIsDockOpen}
                slot={selectedSlot}
            />
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Slot</TableCell>
                        <TableCell>LED</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Bike RFID</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slots.map((slot) => (
                        <SlotListItem
                            key={`rack_slot_${slot.slotNumber}`}
                            classes={classes}
                            slot={slot}
                            setSelectedSlot={setSelectedSlot}
                            isRemoveOpen={isRemoveOpen}
                            setIsRemoveOpen={setIsRemoveOpen}
                            isDockOpen={isDockOpen}
                            setIsDockOpen={setIsDockOpen}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
