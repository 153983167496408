/**
 * The log levels
 */
export enum LogLevel {
    Info = "info",
    Warn = "warn",
    Error = "error",
}

/**
 * A log entry metadata
 */
export interface LoggerMeta {
    [key: string]: any;
    error?: any;
}

/**
 * A log entry
 */
export interface LoggerInfo extends LoggerMeta {
    message: string;
}

/**
 * A logger log method
 */
export interface LoggerMethod {
    (message: string): void;
    (message: string, meta: LoggerMeta): void;
    (info: LoggerInfo): void;
}

/**
 * A logger
 */
export interface Logger {
    /** Logs the specified `info` level message */
    info: LoggerMethod;

    /** Logs the specified `warning` level message */
    warn: LoggerMethod;

    /** Logs the specified `error`` level message */
    error: LoggerMethod;
}

/** The {@link Logger} interface type tag */
export const LoggerTypeTag = Symbol.for("3a00802f-3b97-4f22-9c3d-fb662e0f4ae6");
