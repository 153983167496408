import ow from "ow";

const SEPARATORS_END = new RegExp("[\\\\\\/]+$");
const SEPARATORS_START_END = new RegExp("^[\\\\\\/]+|[\\\\\\/]+$", "g");

function makeSuffix(path: string): string {
    path = String(path);

    const l = path.length;

    if (l < 1) {
        return "";
    }

    const suffix = path.charAt(l - 1);

    return suffix === "/" || suffix === "\\" ? "/" : "";
}

/**
 * Combines the specified strings into an URL path.
 *
 * @function combineUrl
 * @param parts the parts to combine
 * @return The parts combined with '/'
 */
export function combineUrl(...parts: string[]): string {
    const len = parts.length;
    if (len < 1) {
        return "";
    }

    let part = parts[0];
    ow(part, ow.string);

    let suf = makeSuffix(part);

    part = part.replace(SEPARATORS_END, "");

    if (len < 2) {
        return part.concat(suf);
    }

    const buf = [];

    if (part) {
        buf.push(part);
    } else if (suf) {
        buf.push("");
    }

    for (let i = 1; i < len; ++i) {
        part = parts[i];

        ow(part, ow.string);

        if ((!suf && part === "/") || part === "\\") {
            buf.push("/");
        } else {
            const mid = part.replace(SEPARATORS_START_END, "");

            if (mid) {
                const pre = part.charAt(0);

                if (pre === "/" || pre === "\\") {
                    if (buf.length > 0) {
                        if (buf[buf.length - 1] !== "/") {
                            buf.push("/");
                        }
                    } else {
                        buf.push("/");
                    }
                } else if (buf.length > 0 && buf[buf.length - 1] !== "/") {
                    buf.push("/");
                }

                buf.push(mid);

                suf = makeSuffix(part);
            }
        }
    }

    if (suf) {
        buf.push(suf);
    }

    return buf.join("");
}
