import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import WifiIcon from "@material-ui/icons/Wifi";
import { useState } from "react";
import { NetworkForm } from "./networkForm";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        action: {
            marginLeft: "8px",
        },
        icon: {
            marginRight: "8px",
        },
    });
});

export function Network() {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    function handleOpen() {
        setIsOpen(true);
    }

    return (
        <Box>
            <Button
                className={classes.action}
                color="inherit"
                size="small"
                title="Manage the network connectivity state"
                onClick={handleOpen}
            >
                <WifiIcon className={classes.icon} />
                Network
            </Button>
            {isOpen && <NetworkForm isOpen={isOpen} setIsOpen={setIsOpen} />}
        </Box>
    );
}
