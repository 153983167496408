import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import {
    ErrorBoundary,
    LoadingIndicator,
    setDocumentTitle,
} from "../../components";
import { UserProfile } from "../../models";
import { ApplicationBar, StationList } from "./parts";

const StyledGrid = styled(Grid)({
    marginTop: "16px",
    marginBottom: "16px",
});

export interface HomeScreenProps {
    user: UserProfile;
}

export function HomeScreen({ user }: HomeScreenProps) {
    useEffect(function () {
        setDocumentTitle("Home");
    });

    return (
        <Fragment>
            <ApplicationBar title="Home" user={user} />
            <StyledGrid
                container
                direction="column"
                alignItems="flex-start"
                spacing={1}
            >
                <ErrorBoundary>
                    <React.Suspense fallback={<LoadingIndicator />}>
                        <Grid item>
                            <StationList />
                        </Grid>
                    </React.Suspense>
                </ErrorBoundary>
            </StyledGrid>
        </Fragment>
    );
}
