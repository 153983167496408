import moment from "moment";
import { chunk, isNil, isNonEmptyString, isNotNil } from "./helpers";
import {
    StationProtocolMessage,
    tryParseAndIdentifyMessage,
} from "./stationProtocol";

/** A station log file line */
interface LogLine {
    date: string;
    level: string;
    msg: string;
}

/** A parsed station log file line */
export interface LogRecord {
    date: string;
    msg: string;
    meta?: MetaMessage[];
}

/**
 * A Station Protocol message argument
 */
export interface MetaArgument {
    /** The argument id */
    arg: string;
    /** The raw argument id */
    rawArg: string;
    /** The argument value */
    value: string | null;
    /** The raw argument value */
    rawValue?: string | null;
}

/**
 * A Station Protocol message
 */
export interface MetaMessage {
    /** The command id */
    cmd: string;
    /** The raw command id */
    rawCmd: string;
    /** The message arguments */
    args: MetaArgument[];
}

// REQ:{"cmds":[nnn:nnn
const REQ_PREFIX = new RegExp('^REQ\\:\\{"cmds"\\:\\["\\d{3,3}\\:\\d{3,3}');

// RES:nnn:nnn
const RES_PREFIX = new RegExp("^RES\\:\\d{3,3}\\:\\d{3,3}");

function parseRequest(logLine: LogLine): StationProtocolMessage[] | null {
    const logMessage = logLine.msg;
    if (logMessage.length < 1) {
        return null;
    }

    if (!REQ_PREFIX.test(logMessage)) {
        return null;
    }

    try {
        const messageBody = logMessage.substring(4);

        const packet = JSON.parse(messageBody);

        const commands = packet.cmds;

        if (!Array.isArray(commands) || commands.length < 1) {
            return null;
        }

        const messages: StationProtocolMessage[] = [];

        for (const cmd of commands) {
            const message = tryParseAndIdentifyMessage(cmd);

            if (!isNil(message)) {
                messages.push(message);
            }
        }
        return messages;
    } catch (err) {
        return null;
    }
}

function parseResponse(logLine: LogLine): StationProtocolMessage | null {
    const logMessage = logLine.msg;
    if (logMessage.length < 1) {
        return null;
    }

    if (!RES_PREFIX.test(logMessage)) {
        return null;
    }

    try {
        const messageBody = logMessage.substring(4);

        const message = tryParseAndIdentifyMessage(messageBody);

        return message;
    } catch {
        return null;
    }
}

function makeMeta(message: StationProtocolMessage): MetaMessage {
    const { cmd, rawCmd, args } = message;

    const parsedArgs = args.map(function (protocolArg): MetaArgument {
        const { arg, rawArg, value, rawValue } = protocolArg;
        return {
            arg,
            rawArg,
            value,
            rawValue: value === rawValue ? undefined : rawValue,
        };
    });

    return {
        cmd,
        rawCmd,
        args: parsedArgs,
    };
}

function parseLogDate(text: string): string {
    return moment(text, "DD/MM/YYYY h:mm:ss a").format("YYYY-MM-DD HH:mm:ss");
}

function parseLogLine(line: string): LogLine | null {
    line = line.trim();

    const parts = line.split("\t");
    if (parts.length < 2) {
        return null;
    }

    const date = parseLogDate(parts[0]);

    const level = parts[1];

    const msg = parts.slice(2).join("\t");

    return {
        date,
        level,
        msg,
    };
}

export function parseLogRecord(line: string): LogRecord | null {
    const logLine = parseLogLine(line);
    if (isNil(logLine)) {
        return null;
    }

    const date = logLine.date;

    //
    // Try to recognize a `REQ` line
    const req = parseRequest(logLine);
    if (!isNil(req) && req.length > 0) {
        return {
            date,
            msg: "Station Request",
            meta: req.map(makeMeta),
        };
    }

    //
    // Try to recognize a `RES` line
    const res = parseResponse(logLine);
    if (!isNil(res)) {
        return {
            date,
            msg: "API Response",
            meta: [makeMeta(res)],
        };
    }

    return {
        date,
        msg: logLine.msg,
    };
}

function wait(timeout: number): Promise<void> {
    return new Promise<void>(function (resolve) {
        setTimeout(resolve, timeout);
    });
}

async function* defer(parts: readonly string[][]): AsyncGenerator<string[]> {
    for (let i = 0; i < parts.length; ++i) {
        await wait(0);

        yield parts[i];
    }
}

export async function parseLogFile(text: string): Promise<string[]> {
    if (!isNonEmptyString(text)) {
        return [];
    }

    const lines = text.split("\n");

    await wait(100);

    const parts = chunk(lines, 90);

    await wait(100);

    const result: string[] = [];

    for await (let part of defer(parts)) {
        const records = part.map(parseLogRecord);

        for (const record of records) {
            if (isNotNil(record)) {
                result.push(JSON.stringify(record, null, 2));
            }
        }
    }

    return result;
}
