/**
 * The SmartBike Station Protocol
 * See https://cci-clearchannel.atlassian.net/wiki/spaces/SB/pages/99321647/Station-BKO+Communication+Parameter+definitions
 * See https://sap.github.io/chevrotain/docs/
 */

import { createToken, Lexer } from "chevrotain";

export const ID = createToken({ name: "ID", pattern: /[0-9]{2,4}:/ });

export const TAB = createToken({ name: "TAB", pattern: /\t/ });

export const UUID = createToken({
    name: "UUID",
    pattern:
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
});

export const DATE = createToken({
    name: "DATE",
    // Example 26/05/2019 23-37-26
    pattern: /[0-9]{2}\/[0-9]{2}\/[0-9]{4} [0-9]{2}-[0-9]{2}-[0-9]{2}/,
});

export const TEXT = createToken({
    name: "TEXT",
    pattern: /(?:[^\t])+/,
});

export const SPACE = createToken({
    name: "SPACE",
    pattern: /[ ]+/,
    group: Lexer.SKIPPED,
});

// The order of Token definitions passed to the Lexer is important.
// The first PATTERN to match will be chosen not the longest.
export const allTokens = [TAB, ID, UUID, DATE, SPACE, TEXT];

/**
 * The Station Protocol lexer
 */
export const stationProtocolLexer = new Lexer(allTokens);
