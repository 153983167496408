import { selector } from "recoil";
import { getAmbient } from "../context";
import {
    BikeState,
    emptyStationState,
    emptyStationSummary,
    LockState,
    StationState,
    StationSummary,
    TeamMember,
    UserProfile,
} from "../models";
import {
    screenTimeStampState,
    slotTimeStampState,
    stateTimeStampState,
    stationSpecState,
    teamTimeStampState,
} from "./atoms";

/**
 * The state selectors keys
 * See {@link https://recoiljs.org/docs/introduction/core-concepts}
 */
export enum StateSelectorKey {
    /** The current user profile */
    user = "userQuery",

    /** The configured stations */
    stations = "stationsQuery",

    /** The station screen image url */
    stationScreenUrl = "stationScreenUrlQuery",

    /** The station state */
    stationState = "stationStateQuery",

    /** The station summary */
    stationSummary = "stationSummaryQuery",

    /** The station slots */
    stationSlots = "stationSlotsQuery",

    /** The configured station bikes */
    stationBikes = "stationBikesQuery",

    /** The connected team members */
    connectedMembers = "connectedMembers",
}

/** Returns the current user profile */
export const userQuery = selector<UserProfile>({
    key: StateSelectorKey.user,
    get(_options) {
        const { station } = getAmbient();

        return station.getUser();
    },
});

/** Returns the configured stations */
export const stationsQuery = selector<StationSummary[]>({
    key: StateSelectorKey.stations,
    get(_options) {
        const { station } = getAmbient();

        return station.getStations();
    },
});

/** Returns the station screen url */
export const stationScreenUrlQuery = selector<string>({
    key: StateSelectorKey.stationScreenUrl,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return "";
        }

        const timeStamp = get(screenTimeStampState);

        const { station } = getAmbient();

        return station.getScreenImageUrl(stationSpec, timeStamp);
    },
});

/** Returns the station state */
export const stationStateQuery = selector<StationState>({
    key: StateSelectorKey.stationState,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return emptyStationState();
        }

        get(stateTimeStampState);

        const { station } = getAmbient();

        return station.getStationState(stationSpec);
    },
});

/** Returns the station summary */
export const stationSummaryQuery = selector<StationSummary>({
    key: StateSelectorKey.stationSummary,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return emptyStationSummary();
        }

        const { station } = getAmbient();

        return station.getStationSummary(stationSpec);
    },
});

/** Returns the station slots */
export const stationSlotsQuery = selector<LockState[]>({
    key: StateSelectorKey.stationSlots,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return [];
        }

        get(slotTimeStampState);

        const { station } = getAmbient();

        return station.getLocks(stationSpec);
    },
});

/** Returns the configured station bikes */
export const stationBikesQuery = selector<BikeState[]>({
    key: StateSelectorKey.stationBikes,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return [];
        }

        get(slotTimeStampState);

        const { station } = getAmbient();

        return station.getBikes(stationSpec);
    },
});

/** Returns the connected team members */
export const connectedMembersQuery = selector<TeamMember[]>({
    key: StateSelectorKey.connectedMembers,
    get({ get }) {
        const stationSpec = get(stationSpecState);

        if (stationSpec.stationID === 0) {
            return [];
        }

        get(teamTimeStampState);

        const { station } = getAmbient();

        return station.getConnectedMembers(stationSpec);
    },
});
