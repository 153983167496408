import { isNonEmptyString } from "./helpers";

interface V8ErrorConstructor extends ErrorConstructor {
    captureStackTrace(thisArg: any, func: any): void;
}

/**
 * A base class for custom error classes
 */
export class ErrorBase extends Error {
    /**
     * Constructs an instance of {@link ErrorBase}
     *
     * @param message The error message
     */
    public constructor(message?: string) {
        super(message);

        // Replace error name with the class name of the constructor
        // if it has not been overwritten already
        if (!isNonEmptyString(this.name) || this.name === "Error") {
            this.name = this.constructor.name;
        }

        if (!isNonEmptyString(this.message)) {
            this.message = this.constructor.name;
        }

        // Error stack trace available on V8
        // See https://v8.dev/docs/stack-trace-api
        if ((Error as V8ErrorConstructor).captureStackTrace !== undefined) {
            (Error as V8ErrorConstructor).captureStackTrace(
                this,
                this.constructor
            );
        }
    }
}
