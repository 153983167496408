import Box from "@material-ui/core/Box";
import { useRecoilValue } from "recoil";
import { rebootRequestedState } from "../../../state";
import { CardReader } from "./cardReader";
import { KeyPad } from "./keyPad";
import { LogViewer } from "./logViewer";
import { Network } from "./network";
import { Reboot } from "./reboot";

export interface ActionToolbarProps {
    className: string;
}

export function ActionToolbar({ className }: ActionToolbarProps) {
    const isRebootRequested = useRecoilValue(rebootRequestedState);

    return isRebootRequested ? (
        <Box className={className} />
    ) : (
        <Box className={className}>
            <CardReader />
            <KeyPad />
            <Network />
            <Reboot />
            <LogViewer />
        </Box>
    );
}
