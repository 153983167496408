/**
 * The SmartBike Station Protocol
 * See https://cci-clearchannel.atlassian.net/wiki/spaces/SB/pages/99321647/Station-BKO+Communication+Parameter+definitions
 */

import { CstNode, CstParser, ILexingResult, IToken } from "chevrotain";
import { isEmpty, isObject } from "../../helpers";
import { StationProtocolError } from "./diagnostic";
import { allTokens, DATE, ID, TAB, TEXT, UUID } from "./lexer";

/**
 * Parses the SmartBike Station Protocol messages
 * See https://sap.github.io/chevrotain/docs/
 */
export class StationProtocolParser extends CstParser {
    private Message = this.RULE("Message", (): void => {
        this.SUBRULE(this.Command);
        this.SUBRULE1(this.Sequence);
        this.OPTION((): void => {
            this.SUBRULE(this.Arguments);
        });
    });

    private Command = this.RULE("Command", (): void => {
        this.CONSUME(ID);
    });

    private Sequence = this.RULE("Sequence", (): void => {
        this.CONSUME(TEXT);
    });

    private Arguments = this.RULE("Arguments", (): void => {
        this.CONSUME(TAB);
        this.MANY_SEP({
            // The "SEP" property must appear before the "DEF" property.
            SEP: TAB,
            DEF: (): void => {
                this.SUBRULE2(this.Argument);
            },
        });
    });

    private Argument = this.RULE("Argument", (): void => {
        this.CONSUME(ID);
        this.OPTION((): void => {
            this.SUBRULE(this.Value);
        });
    });

    private Value = this.RULE("Value", (): void => {
        this.OR([
            { ALT: (): IToken => this.CONSUME(UUID) },
            { ALT: (): IToken => this.CONSUME(DATE) },
            { ALT: (): IToken => this.CONSUME(TEXT) },
        ]);
    });

    public constructor() {
        super(allTokens);

        // It is very important to call this after all the rules have been defined,
        // otherwise the parser may not work correctly as it will lack information
        // derived during the self analysis phase.
        this.performSelfAnalysis();
    }

    /**
     * Parses the specified message
     * @param input The lexer result
     */
    public parseMessage(input: ILexingResult): CstNode {
        if (!isObject(input)) {
            throw new Error("Invalid argument:input");
        }

        if (!isEmpty(input.errors)) {
            throw new StationProtocolError({
                lexingErrors: input.errors,
                parsingErrors: [],
            });
        }

        // "input" is a setter which will reset the parser state.
        this.input = input.tokens;

        const result = this.Message();

        if (!isEmpty(this.errors)) {
            throw new StationProtocolError({
                lexingErrors: [],
                parsingErrors: this.errors,
            });
        }

        return result;
    }
}

export interface CommandContext extends CstNode {
    ID: IToken[];
}

export interface SequenceContext extends CstNode {
    TEXT: IToken[];
}

export interface ArgumentsContext extends CstNode {
    Argument: CstNode[];
}

export interface ArgumentContext extends CstNode {
    ID: IToken[];
    Value: CstNode[];
}

export interface ValueContext extends CstNode {
    DATE?: IToken[];

    TEXT?: IToken[];

    UUID?: IToken[];
}

export interface MessageContext extends CstNode {
    Command: CstNode[];

    Sequence: CstNode[];

    Arguments: CstNode[];

    Argument: CstNode[];

    Value: CstNode[];
}

/**
 * The Station Protocol parser
 */
export const stationProtocolParser = new StationProtocolParser();
