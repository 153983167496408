import { Container } from "inversify";
import { Ambient, AmbientTypeTag } from "./context";
import { ConfigStore, ConfigStoreTypeTag } from "./services/configStore";
import { LocalConfig } from "./services/configStore/providers";
import { FetchProvider, FetchProviderTypeTag } from "./services/fetch";
import { PlatformFetch } from "./services/fetch/providers";
import { Logger, LoggerTypeTag } from "./services/logger";
import { ConsoleLogger, SilentLogger } from "./services/logger/providers";
import { StationEmulator, StationEmulatorTypeTag } from "./services/station";
import { StationEmulatorApi } from "./services/station/providers";

/**
 * Registers the application services
 */
export function registerServices(): Container {
    const container = new Container();

    //
    // Load the Application Insights package as early as possible
    const nodeEnv = process.env.NODE_ENV;
    if (nodeEnv === "production") {
        container
            .bind<Logger>(LoggerTypeTag)
            .toDynamicValue(function (_ctx) {
                return new SilentLogger();
            })
            .inSingletonScope();
    } else {
        container
            .bind<Logger>(LoggerTypeTag)
            .toDynamicValue(function (_ctx) {
                return new ConsoleLogger();
            })
            .inSingletonScope();
    }

    container
        .bind<ConfigStore>(ConfigStoreTypeTag)
        .toDynamicValue(function (_ctx) {
            return new LocalConfig();
        })
        .inSingletonScope();

    container
        .bind<StationEmulator>(StationEmulatorTypeTag)
        .toDynamicValue(function (ctx) {
            const cnt = ctx.container;
            return new StationEmulatorApi(
                cnt.get<Logger>(LoggerTypeTag),
                cnt.get<FetchProvider>(FetchProviderTypeTag)
            );
        })
        .inSingletonScope();

    container
        .bind<Ambient>(AmbientTypeTag)
        .toDynamicValue(function (ctx) {
            const cnt = ctx.container;
            return new Ambient(
                cnt.get<Logger>(LoggerTypeTag),
                cnt.get<ConfigStore>(ConfigStoreTypeTag),
                cnt.get<StationEmulator>(StationEmulatorTypeTag)
            );
        })
        .inSingletonScope();

    container
        .bind<FetchProvider>(FetchProviderTypeTag)
        .toDynamicValue(function (_ctx) {
            return new PlatformFetch();
        })
        .inSingletonScope();

    return container;
}
