import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { isNonEmptyString, show } from "../../../common";
import { useAmbient } from "../../../context";
import { stationStateQuery } from "../../../state";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        content: {
            minWidth: "480px",
            minHeight: "260px",
        },
        notification: {
            marginTop: "24px",
        },
    });
});

export interface NetworkFormProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export const NetworkForm: React.FC<NetworkFormProps> = ({
    isOpen,
    setIsOpen,
}: NetworkFormProps) => {
    const classes = useStyles();

    const { station, stationSpec } = useAmbient();

    const { isNetworkDisabled } = useRecoilValue(stationStateQuery);

    const [errorMessage, setErrorMessage] = useState("");

    const [isSubmitting, setSubmitting] = useState(false);

    const [isNetworkEnabled, setNetworkEnabled] = useState(
        isNetworkDisabled !== true
    );

    const [networkLabel, setNetworkLabel] = useState(
        isNetworkEnabled === true ? "Enabled" : "Disabled"
    );

    function handleCancel() {
        setErrorMessage("");
        setIsOpen(false);
    }

    function submitForm() {
        setErrorMessage("");
        station
            .updateNetwork(stationSpec, {
                isNetworkDisabled: !isNetworkEnabled,
            })
            .then(function () {
                setSubmitting(false);
                setIsOpen(false);
            })
            .catch(function (err) {
                setSubmitting(false);
                setErrorMessage(show(err));
            });
    }

    function handleChange(
        _evt: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void {
        setNetworkEnabled(checked === true);
        setNetworkLabel(checked ? "Enabled" : "Disabled");
    }

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>Network</DialogTitle>
            <form>
                <DialogContent className={classes.content}>
                    <DialogContentText>
                        Enable or disable network connectivity.
                    </DialogContentText>
                    <Box>
                        <FormControlLabel
                            label={networkLabel}
                            control={
                                <Switch
                                    name="networkEnabled"
                                    onChange={handleChange}
                                    checked={isNetworkEnabled}
                                />
                            }
                        />
                    </Box>
                    <Box className={classes.notification}>
                        {isSubmitting && <LinearProgress />}
                        {isNonEmptyString(errorMessage) && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                    <Button
                        color="default"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
