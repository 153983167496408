import { ErrorBase } from "./errorBase";
import { ErrorReport, parseError } from "./errorReport";

/**
 * Details of an error
 *
 * @example
 * ```ts
 *  const details = {
 *    "domain": "users",
 *    "scope": "profile",
 *    "statusCode": 400,
 *    "phrase": "Bad Request",
 *    "error": "bad_request",
 *    "reason": "invalid_argument",
 *    "message": "The parameter `id` is invalid."
 * };
 * ```
 */
export interface SomeErrorOptions {
    /** The error domain */
    domain: string;

    /** The scope within the error domain */
    scope: string;

    /** The HTTP status code */
    statusCode: number;

    /**
     * The HTTP status phrase.
     * The standard status phrase will be set if not provided.
     */
    phrase?: string;

    /**
     * The status code as a literal code.
     * Will be set from the status code value if not provided.
     */
    error?: string;

    /** The reason of the error as a literal code */
    reason: string;

    /** A descriptive message */
    message: string;
}

/**
 * The JSON representation of {@link SomeError}
 */
export interface SomeErrorAttributes extends ErrorReport {
    /** The stack trace */
    stack?: string;
}

/**
 * A generic error
 */
export class SomeError extends ErrorBase implements ErrorReport {
    /** The error domain  */
    public readonly domain: string;

    /** The scope within the domain  */
    public readonly scope: string;

    /** The HTTP status code */
    public readonly statusCode: number;

    /** The HTTP status phrase */
    public readonly phrase: string;

    /** The status code as a literal code */
    public readonly error: string;

    /** The reason of the error as a literal code */
    public readonly reason: string;

    public constructor(options: SomeErrorOptions) {
        const parsed = parseError(options);

        super(parsed.message);

        this.domain = parsed.domain;

        this.scope = parsed.scope;

        this.statusCode = parsed.statusCode;

        this.phrase = parsed.phrase;

        this.error = parsed.error;

        this.reason = parsed.reason;
    }

    /**
     * Returns the textual representation
     */
    public toString(): string {
        return "Error: ".concat(this.message);
    }

    /**
     * Returns the JSON representation
     */
    public toJSON(): SomeErrorAttributes {
        return {
            domain: this.domain,
            scope: this.scope,
            statusCode: this.statusCode,
            phrase: this.phrase,
            error: this.error,
            reason: this.reason,
            message: this.message,
            stack: this.stack,
        };
    }

    /**
     * Returns the error report
     */
    public toReport(): ErrorReport {
        return {
            domain: this.domain,
            scope: this.scope,
            statusCode: this.statusCode,
            phrase: this.phrase,
            error: this.error,
            reason: this.reason,
            message: this.message,
        };
    }
}
