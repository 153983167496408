import React from "react";
import invariant from "ts-invariant";
import { isNil, isNotNil } from "../common/helpers";
import { Ambient } from "./ambient";

/** The global runtime context value */
export interface AmbientContextValue {
    /** The runtime context */
    ambient?: Ambient;
}

export type AmbientContextType = React.Context<AmbientContextValue>;

let ambientContext: AmbientContextType;

/** Returns the global runtime context object */
export function getAmbientContext(): AmbientContextType {
    if (isNil(ambientContext)) {
        ambientContext = React.createContext<AmbientContextValue>({});
    }
    return ambientContext;
}

let ambientInstance: Ambient;

/** Returns the global runtime object */
export function getAmbient(): Ambient {
    invariant(
        isNotNil(ambientInstance),
        "Could not find `ambient` in instance. " +
            "Please wrap the root component in an <AmbientProvider>."
    );
    return ambientInstance;
}

/** Sets the global runtime object */
export function setAmbient(ambient: Ambient): void {
    ambientInstance = ambient;
}
