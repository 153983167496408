import Container from "@material-ui/core/Container";
import { styled } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useParams,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isNil } from "./common";
import {
    ErrorBoundary,
    LoadingIndicator,
    ResourceNotFound,
} from "./components";
import { HomeScreen } from "./screens/home/homeScreen";
import { LoginScreen } from "./screens/login/loginScreen";
import {
    StationRouteParams,
    StationScreen,
    StationScreenProps,
} from "./screens/station/stationScreen";
import { browserTimeStampState, stationsQuery, userQuery } from "./state";

const StyledContainer = styled(Container)({
    paddingLeft: "24px",
    paddingRight: "24px",
});

function GuardedStationScreen(props: StationScreenProps) {
    const { env, stationID } = useParams<StationRouteParams>();

    const stationIDValue = parseInt(stationID, 10);

    const stations = useRecoilValue(stationsQuery);

    const found = stations.find(function (station) {
        return station.stationID === stationIDValue && station.env === env;
    });

    return isNil(found) ? <ResourceNotFound /> : <StationScreen {...props} />;
}

export function App() {
    const user = useRecoilValue(userQuery);

    const setBrowserTimeStamp = useSetRecoilState(browserTimeStampState);

    useEffect(function () {
        const timer = setInterval(function () {
            setBrowserTimeStamp(Date.now());
        }, 5 * 1000);

        return function stopTimer() {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledContainer maxWidth={false}>
            <Router>
                <ErrorBoundary>
                    <React.Suspense fallback={<LoadingIndicator />}>
                        <Switch>
                            <Route exact path="/">
                                {user.isAuthenticated === true ? (
                                    <Redirect to="/home" />
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/login">
                                <LoginScreen />
                            </Route>
                            <Route exact path="/home">
                                <HomeScreen user={user} />
                            </Route>
                            <Route path="/env/:env/station/:stationID">
                                <GuardedStationScreen user={user} />
                            </Route>
                            <Route path="*">
                                <ResourceNotFound />
                            </Route>
                        </Switch>
                    </React.Suspense>
                </ErrorBoundary>
            </Router>
        </StyledContainer>
    );
}
