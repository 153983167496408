import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useState } from "react";
import { LogForm } from "./logForm";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        action: {
            marginLeft: "8px",
        },
        icon: {
            marginRight: "8px",
        },
    });
});

export function LogViewer() {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    function handleOpen() {
        setIsOpen(true);
    }

    return (
        <Box>
            <Button
                className={classes.action}
                color="inherit"
                size="small"
                title="View the station log file"
                onClick={handleOpen}
            >
                <AssignmentIcon className={classes.icon} />
                Log
            </Button>
            {isOpen && <LogForm isOpen={isOpen} setIsOpen={setIsOpen} />}
        </Box>
    );
}
