import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { HomeButton, SessionToolbar, UserToolbar } from "../../../components";
import { UserProfile } from "../../../models";

const useStyles = makeStyles(function (theme: Theme) {
    return createStyles({
        leftPanel: {
            marginLeft: theme.spacing(2),
            flex: "auto",
        },
        rightPanel: {
            display: "flex",
            paddingLeft: "16px",
        },
        rightIcon: {
            marginRight: "8px",
            marginLeft: "8px",
        },
        action: {
            paddingTop: "0",
        },
    });
});

export interface ApplicationBarProps {
    title: string;
    user: UserProfile;
}

export function ApplicationBar({ user, title }: ApplicationBarProps) {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <HomeButton />

                <Box className={classes.leftPanel}>
                    <Typography component="span" variant="body1">
                        {`SmartBike Station - ${title}`}
                    </Typography>
                </Box>

                <UserToolbar user={user} classes={classes} />

                <SessionToolbar user={user} classes={classes} />
            </Toolbar>
        </AppBar>
    );
}
