import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useLocation } from "react-router-dom";

export function ResourceNotFound() {
    const location = useLocation();

    return (
        <Box paddingTop="16px">
            <Alert variant="standard" severity="error">
                The requested resource (<code>{location.pathname}</code>) was
                not found.
            </Alert>
        </Box>
    );
}
