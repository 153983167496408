import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import React, { ErrorInfo, ReactNode } from "react";
import { show } from "../common";

export interface ErrorBoundaryProps {}

export interface ErrorBoundaryState {
    readonly hasError: boolean;
    readonly message: string;
}

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    public constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = { hasError: false, message: "" };
    }

    public static getDerivedStateFromError(
        err: any
    ): Partial<ErrorBoundaryState> | null {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, message: show(err) };
    }

    public componentDidCatch(_error: Error, _errorInfo: ErrorInfo): void {}

    public render(): ReactNode {
        const { hasError, message } = this.state;

        if (hasError === true) {
            // You can render any custom fallback UI
            return (
                <Alert variant="standard" severity="error">
                    <Box>Oops! Something went wrong...</Box>
                    <Box>{message}</Box>
                </Alert>
            );
        }

        return this.props.children;
    }
}
