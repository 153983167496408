import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { isNonEmptyString, show } from "../../../common";
import { useAmbient } from "../../../context";
import { SlotBike } from "./types";
import { validateBikeRFID, validateSlot } from "./validation";

const useStyles = makeStyles(function (_theme: Theme) {
    return createStyles({
        content: {
            minWidth: "480px",
            minHeight: "260px",
        },
        notification: {
            marginTop: "24px",
        },
    });
});

export interface DockBikeFormProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    slot: SlotBike;
}

interface DockBikeFormValues {
    slotNumber: string;
    bikeRFID: string;
}

export const DockBikeForm: React.FC<DockBikeFormProps> = ({
    isOpen,
    setIsOpen,
    slot,
}: DockBikeFormProps) => {
    const classes = useStyles();

    const { station, stationSpec } = useAmbient();

    const [errorMessage, setErrorMessage] = useState("");

    function handleCancel() {
        setErrorMessage("");
        setIsOpen(false);
    }

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>Dock Bike</DialogTitle>
            <Formik
                initialValues={{
                    slotNumber: slot.slotNumber.toFixed(),
                    bikeRFID: slot.bikeRFID,
                }}
                validate={(values) => {
                    const errors: Partial<DockBikeFormValues> = {};
                    validateBikeRFID(values, errors);
                    validateSlot(values, errors);
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setErrorMessage("");
                    station
                        .dockBike(
                            stationSpec,
                            parseInt(values.slotNumber, 10),
                            values.bikeRFID
                        )
                        .then(function () {
                            setSubmitting(false);
                            setIsOpen(false);
                        })
                        .catch(function (err) {
                            setSubmitting(false);
                            setErrorMessage(show(err));
                        });
                }}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <DialogContent className={classes.content}>
                            <DialogContentText>
                                Confirm to dock the bike in the station slot.
                            </DialogContentText>
                            <Box>
                                <Field
                                    component={TextField}
                                    name="slotNumber"
                                    type="text"
                                    label="Slot Number"
                                    maxLength={40}
                                    autoComplete="off"
                                />
                            </Box>
                            <Box>
                                <Field
                                    component={TextField}
                                    name="bikeRFID"
                                    type="text"
                                    label="Bike RFID"
                                    maxLength={40}
                                    autoComplete="off"
                                />
                            </Box>
                            <Box className={classes.notification}>
                                {isSubmitting && <LinearProgress />}
                                {isNonEmptyString(errorMessage) && (
                                    <Alert severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                Submit
                            </Button>
                            <Button
                                color="default"
                                onClick={handleCancel}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};
