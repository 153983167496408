import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

export interface ApplicationBarProps {
    title: string;
}

export function ApplicationBar({ title }: ApplicationBarProps) {
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography variant="body1">
                    {`SmartBike Station - ${title}`}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
